<template>
    <v-card>
        <v-card-title>
            <p class='mb-0 flow-title'>{{ $t('xm.title', {year: year}) }} </p>
            <v-spacer/>
            <p class='mb-0'>{{ userName }}</p>
        </v-card-title>

        <v-progress-linear :value='progressValue'/>

        <v-stepper v-model='headerStepper'>
            <v-stepper-header>
                <v-stepper-step :complete='headerStepper > 1' :step='1'>{{
                    $t('xm.stepper.headers.step1')
                    }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete='headerStepper > 2' :step='2'>{{
                    $t('xm.stepper.headers.step2')
                    }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete='headerStepper > 3' :step='3'>{{
                    $t('xm.stepper.headers.step3')
                    }}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper v-model='stepper'>
                <v-stepper-items>
                    <v-stepper-content step='1'>
                        <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                            {{ $t('xm.stepper.form.control.title', {name: firstName}) }}
                        </p>
                        <v-form @submit.prevent='goNextControl()'>
                            <v-card v-for='item in form.huisartsAfspraak.selectItems'
                                    :key='item.value'
                                    class="card-option"
                                    :class="{'card-option--selected': isActive(item.value, form.huisartsAfspraak.value)}"
                                    @click='handleControlClick(item)'>
                                <span>{{ $t(item.text) }}</span>
                            </v-card>
                            <p class='error--text' v-if='controlError'>{{ $t('xm.stepper.form.control.required') }}</p>
                            <action-container no-going-back no-going-next big-text :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step='2'>
                        <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                            {{ $t('xm.stepper.form.bloodControl.title') }}</p>
                        <v-form @submit.prevent='goNextBloodControl()'>
                            <div class="d-flex justify-center">
                                <v-card v-for='(item, idx) in form.bloedglucosecontrole.selectItems'
                                        :key='item.value'
                                        class="card-option card-option--vertical"
                                        :class="{'card-option--selected': isActive(item.value, form.bloedglucosecontrole.value)}"
                                        @click='handleBloodControlClick(item)'>
                                    <v-icon large color="primary">
                                        {{ idx === 0 ? 'done' : 'close' }}
                                    </v-icon>
                                    <span>{{ $t(item.text) }}</span>
                                </v-card>
                            </div>
                            <p class='error--text' v-if='bloodControlError'>{{ $t('xm.stepper.form.control.required') }}</p>
                            <action-container @goPrev='goPrev' no-going-next big-text :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step='3'>
                        <v-form @submit.prevent='goNextResult()'>
                            <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                                {{ $t('xm.stepper.form.result.title') }}</p>

                            <div class="d-flex align-top">
                                <v-text-field v-model='form.bloedsuikerwaarden.value'
                                              :label='$t(form.bloedsuikerwaarden.label)'
                                              :rules='form.bloedsuikerwaarden.rules'
                                              outlined
                                              @keypress="onlyNumbersAllowed"
                                              v-input-integer-converter>
                                    <template v-slot:append>
                                        <span>{{ $t('xm.stepper.form.result.number.unit') }}</span>
                                    </template>
                                </v-text-field>
                                <v-btn
                                        type="submit"
                                        class="ml-4 elevation-0"
                                        dark
                                        fab
                                        color="primary"
                                >
                                    <v-icon dark>
                                        chevron_right
                                    </v-icon>
                                </v-btn>
                            </div>

                            <div class='divider d-flex justify-center align-center mb-10'>
              <span class='divider__text px-4'>
                {{ $t('xm.stepper.form.result.divider') }}
              </span>
                            </div>

                            <v-card v-for='item in form.bloedsuikerwaardenOpties.selectItems'
                                    :key='item.value'
                                    class="card-option"
                                    :class="{'card-option--selected': isActive(item.value, form.bloedsuikerwaardenOpties.value)}"
                                    @click='handleResultSelectionClick(item)'>
                                <v-row>
                                    <v-col class='d-flex justify-space-between align-center'>
                                        <span>{{ $t(`xm.stepper.form.result.selection.${item.value}.label`) }}</span>
                                        <span class="font-weight-bold">
                    {{ $t(`xm.stepper.form.result.selection.${item.value}.unit`) }}
                  </span>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <p class='error--text' v-if='resultError'>{{ $t(resultError) }}</p>
                            <action-container @goPrev='goPrev' no-going-next big-text :stepper='stepper'/>
                            <p class='grey4--text mt-8'>{{ $t('xm.stepper.form.result.selection.DM.asterix') }}</p>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step='4'>
                        <v-form @submit.prevent='goNextDiabeticConfirmed()'>
                            <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                                {{ $t('diabeticConfirmed.title') }}
                            </p>
                            <p style='white-space: pre-wrap'>{{ $t('diabeticConfirmed.description') }}</p>

                            <action-container @goPrev='goPrev' :stepper='stepper'/>
                        </v-form>
                    </v-stepper-content>

                    <v-stepper-content step='5'>
                        <v-form @submit.prevent='goNextControlPlanned()'>
                            <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                                {{ $t('xm.stepper.form.controlPlanned.title') }}</p>

                            <action-container @goPrev='goPrev' :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step='6'>
                        <v-form @submit.prevent='goNext()'>
                            <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                                {{ $t('xm.stepper.form.pregnant.title') }}</p>
                            <action-container @goPrev='goPrev' :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <user-info-steppers :stepper='stepper'
                                        :begin-stepper='7'
                                        :vrouw='vrouw'
                                        @goNext='goNextUserInfo'
                                        @goNextBeforeCommunication='goNextUserInfoBeforeCommunication'
                                        @goPrev='goPrev'/>

                    <v-stepper-content :step='lastStepNumber' class='thanks' v-if='!hasSubmitError'>
                        <v-icon size='200' color='green'>check_circle</v-icon>
                        <p class='text-h5 my-4'>{{ $t('xm.stepper.thanks.title', {name: firstName}) }}</p>
                        <p class='light--text my-4'>{{ $t('xm.stepper.thanks.description') }}</p>
                    </v-stepper-content>

                    <v-stepper-content :step='lastStepNumber' class='thanks' v-else>
                        <v-icon size='200' color='error'>error</v-icon>
                        <p class='text-h5 my-4 text-center'>{{ $t('xm.stepper.thanks_error.title', {name: firstName}) }}</p>
                        <p class='light--text my-4 text-center'>{{ $t('xm.stepper.thanks_error.description') }}</p>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>

        </v-stepper>
        <home-back-button-handler :step='stepper' @popstate='goPrev'/>
    </v-card>
</template>

<script>
import LocalePicker from '@/modules/core/components/LocalePicker';
import ActionContainer from '@/modules/home/ActionContainer';
import {BloedglucosecontroleOptions, ConsultatieHuisartsType, NumberResultOptions, XmForm,} from '@/modules/home/forms/xm';
import UserInfoSteppers from '@/modules/home/userInfoSteppers';
import DateField from '@/modules/core/components/forms/DateField';
import {Vrouw} from '@/modules/vrouw/domains';
import {UserInfoSteppersForm} from '@/modules/home/forms/userInfoSteppersForm';
import {saveOpvolging} from '@/modules/home/api';
import {Opvolging} from '@/modules/home/domains';
import HomeBackButtonHandler from '@/modules/home/HomeBackButtonHandler.vue';
import {onlyNumberAllowed} from "@/modules/core/directives/inputDecimalConvert";

export default {
    name: 'xm',
    components: {HomeBackButtonHandler, DateField, UserInfoSteppers, ActionContainer, LocalePicker},
    props: {
        vrouw: Vrouw,
        opvolging: Opvolging,
        hash: String,
    },
    data() {
        return {
            stepper: 1,
            headerStepper: 1,
            form: new XmForm(this.$i18n),
            userInfoForm: new UserInfoSteppersForm(this.$i18n),
            controlError: false,
            bloodControlError: false,
            resultError: '',
            stepperFollowList: [],
            hasSubmitError: false,
            lastStepNumber: 11
        };
    },
    mounted() {
    },
    computed: {
        firstName() {
            return this.vrouw?.voornaam ?? '';
        },
        userName() {
            return this.vrouw?.fullName ?? '';
        },
        year() {
            return this.opvolging.opvolgingType.replace('j', '');
        },
        progressValue() {
            return (this.stepper / this.lastStepNumber) * 100;
        },
    },
    methods: {
        onlyNumbersAllowed(evt) {
            return onlyNumberAllowed(evt);
        },
        goPrev() {
            this.stepper = this.stepperFollowList.pop();
        },
        goNextControl() {
            const value = this.form.huisartsAfspraak.value;
            this.controlError = Boolean(!value);

            if (this.controlError) {
                return;
            }

            this.stepperFollowList.push(this.stepper);

            switch (value) {
                case ConsultatieHuisartsType.Ja: {
                    this.stepper += 1;
                    break;
                }
                case ConsultatieHuisartsType.NeeMaarGepland: {
                    this.stepper = 5;
                    break;
                }
                case ConsultatieHuisartsType.NeeWantZwanger: {
                    this.stepper = 6;
                    break;
                }
                case ConsultatieHuisartsType.Nee: {
                    this.goToUserInfo();
                    break;
                }
            }
        },
        goNextBloodControl() {
            this.bloodControlError = Boolean(!this.form.bloedglucosecontrole.value);

            if (!this.bloodControlError) {
                this.stepperFollowList.push(this.stepper);

                switch (this.form.bloedglucosecontrole.value) {
                    case BloedglucosecontroleOptions.YES: {
                        this.stepper += 1;
                        break;
                    }
                    case BloedglucosecontroleOptions.NO: {
                        this.goToUserInfo();
                        break;
                    }
                }

            }
        },
        goNextResult() {
            this.resultError = '';
            if (!Boolean(this.form.bloedsuikerwaarden.value || this.form.bloedsuikerwaardenOpties.value)) {
                this.resultError = 'xm.stepper.form.result.required';
            }
            this.form.bloedsuikerwaarden.isSubmitted = true;

            this.$nextTick(() => {
                if (this.form.bloedsuikerwaarden.value) {
                    const errorFunc = (this.form.bloedsuikerwaarden.rules || []).at(0);
                    if (errorFunc) {
                        const message = errorFunc(this.form.bloedsuikerwaarden.value);
                        this.resultError = message === true ? '' : message;
                    }
                }

                if (!this.resultError) {
                    if (this.form.bloedsuikerwaardenOpties.value === NumberResultOptions.DIABETIC) {
                        this.goNext();
                    } else {
                        this.stepperFollowList.push(this.stepper);
                        this.goToUserInfo();
                    }
                }
            });


        },
        goNextControlPlanned() {
            this.stepperFollowList.push(this.stepper);
            this.goToUserInfo();
        },
        goNextDiabeticConfirmed() {
            this.stepperFollowList.push(this.stepper);
            this.goToUserInfo();
        },
        goNextUserInfo(form, userInfoForm) {
            this.userInfoForm = userInfoForm;
            this.goNext(form);
        },
        goNextUserInfoBeforeCommunication(form, userInfoForm) {
            this.goNextUserInfo(form, userInfoForm);

            if (!form || form.validate()) {
                if (this.form.bloedsuikerwaardenOpties.value === NumberResultOptions.DIABETIC) {
                    this.stepper++;
                    this.stepper++;
                }
            }

        },
        goToUserInfo() {
            this.stepper = 7;
        },
        goNext(form) {
            if (!form || form.validate()) {
                this.stepperFollowList.push(this.stepper);
                this.stepper += 1;
            }
        },
        isActive(itemValue, formValue) {
            return itemValue === formValue;
        },
        getIcon(isActive) {
            return isActive ? 'done' : 'close';
        },
        getClass(isActive) {
            let output = 'my-6 py-4 px-2';

            if (isActive) {
                output = `${output} control-active`;
            }

            return output;
        },
        handleControlClick(item) {
            this.form.huisartsAfspraak.value = item.value;
            this.controlError = false;
            this.goNextControl();
        },
        handleBloodControlClick(item) {
            this.form.bloedglucosecontrole.value = item.value;
            this.bloodControlError = false;
            this.goNextBloodControl();
        },
        handleResultSelectionClick(item) {
            this.form.bloedsuikerwaardenOpties.value = item.value;
            this.goNextResult();
        },
        async handleSubmit() {
            try {
                await saveOpvolging(this.opvolging, {...this.form.toJSON(), ...this.userInfoForm.toJSON()}, this.hash);
            } catch (e) {
                this.hasSubmitError = true;
            }
        },
    },
    watch: {
        stepper() {
            if (this.stepper >= this.lastStepNumber) {
                this.handleSubmit();
            }

            const stepper2Begin = 2;
            const stepper3Begin = 5;
            const stepper4Begin = this.lastStepNumber;

            if (stepper4Begin <= this.stepper) {
                this.headerStepper = 4;
                return;
            }

            if (stepper3Begin <= this.stepper) {
                this.headerStepper = 3;
                return;
            }

            if (stepper2Begin <= this.stepper) {
                this.headerStepper = 2;
                return;
            }

            this.headerStepper = 1;
        },
        'form.bloedsuikerwaarden.value'(newValue) {
            let output = NumberResultOptions.DIABETIC;

            if (newValue < 100) {
                output = NumberResultOptions.NORMAL;
            }
            if (newValue >= 100 && newValue <= 125) {
                output = NumberResultOptions.CRAZY;
            }

            this.form.bloedsuikerwaardenOpties.value = output;
        },
    },
};
</script>

<style scoped lang='scss'>
::v-deep .v-stepper__wrapper {
  max-width: 500px;
  margin: auto;

  @media screen and (min-width: 600px) {
    //min-height: 400px;
  }
}

.control-active {
  border: solid 2px var(--v-primary-base);
  background-color: var(--v-primary-base);
  color: white;
}

.full-width {
  ::v-deep .v-stepper__wrapper {
    max-width: 600px;
  }
}

::v-deep a {
  color: var(--v-grey4-base) !important;
}

.divider {
  position: relative;

  &__text {
    background: white;
    z-index: 2;
    color: var(--v-light-base);
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 100%;
    background: var(--v-light-base);
  }
}

.thanks {
  ::v-deep .v-stepper__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
