import { AxiansForm, FormItem } from '@/modules/core/components/forms/forms.model';

export class RegistrationDiabeticTeamForm extends AxiansForm {
	diabetesteamId: FormItem;

	constructor() {
		super();

		this.diabetesteamId = new FormItem(
			'diabeticTeam',
			'registration.stepper.diabeticTeam.diabeticTeam',
			''
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			diabeticTeam: this.diabetesteamId.value,
		};
	}
}
