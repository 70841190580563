import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import {store} from './../stores/main-store';
import {AxiansError, ValidationCode} from '@/modules/core/errors/errors.domain';
import {getEnv, getValueFromObject} from "@/modules/core/utils";

class HttpServiceConfig {
    public silent: boolean

    constructor(silent: boolean) {
        this.silent = silent
    }
}

export class HttpService {
    private axiosInstance: AxiosInstance = axios.create({
        baseURL: getEnv("VUE_APP_BASE_URL"),
        headers: {
            "X-ZZ": getEnv("VUE_APP_X_ZZ")
        }
    });

    constructor(httpServiceConfig) {
        this._configInterceptors(httpServiceConfig);
    }

    get<T>(url: string, params: Object | undefined = undefined): Promise<T> {
        return this.axiosInstance.get(url, {params: params});
    }

    post<T>(url: string, data: Object): Promise<T> {
        return this.axiosInstance.post(url, data);
    }

    put<T>(url: string, data: Object): Promise<T> {
        return this.axiosInstance.put(url, data);
    }

    delete<T>(url: string): Promise<T> {
        return this.axiosInstance.delete(url);
    }

    private static addAuthorizationHeader(config: AxiosRequestConfig, token?: string) {
        config.headers = Object.assign(
            config.headers,
            token ? {Authorization: `Bearer ${token}`} : {}
        );
        return config;
    }

    private _configInterceptors(httpServiceConfig) {
        this.axiosInstance.interceptors.response.use(
            (response: AxiosResponse) => response.data,
            ({response}: { response: AxiosResponse }) => {
                let errors;
                if (response.data.errors) {
                    errors = response.data.errors.map((error) => new AxiansError(error, response.status));
                }
                if (response.data.error) {
                    errors = [new AxiansError(response.data.error, response.status)];
                }
                if (response.status === 401 && !httpServiceConfig.silent) {
                    store.commit('error/ADD_ERROR', new AxiansError({
                        code: ValidationCode.NOT_AUTHORIZED,
                        message: 'unauthorized'
                    }, 401));
                }
                if (errors) {
                    console.error(errors);
                    return Promise.reject(errors);
                } else {
                    return Promise.reject()
                }
            }
        );
    }
}

export const httpService = new HttpService(new HttpServiceConfig(false));
export const httpSilentService = new HttpService(new HttpServiceConfig(true));


export type PaginationType<T> = {
    content: T[];
    pageNumber: number;
    pageSize: number;
    totalElements: number;
    totalPages: number;
}

export function getQueryString(searchQuery: string, options: any, mapClassKeysWithApiKeys: any = {}): string {
    const query: string[] = []

    if (searchQuery.length) {
        query.push(`query=${searchQuery}`)
    }

    const page = getValueFromObject(options, 'page', 0);
    if (page - 1 >= 0) {
        query.push(`page=${page - 1}`)
    }

    const itemsPerPage = getValueFromObject(options, 'itemsPerPage', 0);
    if (itemsPerPage > 0) {
        query.push(`size=${itemsPerPage}`);
    }

    const sortBys = getValueFromObject(options, 'sortBy', [])

    const sortDesc = getValueFromObject(options, 'sortDesc', [])
    sortBys.forEach((sortElement, index) => {
        let sortDirection = 'asc'
        if (sortDesc.length >= index) {
            sortDirection = sortDesc[index] ? 'asc' : 'desc'
        }
        if (mapClassKeysWithApiKeys[sortElement]) {
            query.push(`sort=${mapClassKeysWithApiKeys[sortElement]},${sortDirection}`)
        }
    })

    return query.join("&")
}
