import {getValueFromObject} from "@/modules/core/utils";


export class Diabetesteam {
    diabetesteamId: string;
    naam: string;
    email: string;

    constructor(diabetesTeam: any) {
        this.diabetesteamId = getValueFromObject(diabetesTeam, 'diabetesteamId', "")
        this.naam = getValueFromObject(diabetesTeam, 'naam', "")
        this.email = getValueFromObject(diabetesTeam, 'email', "");
    }
}

