





















import Vue from 'vue';
import Loader from '@/modules/core/components/Loader.vue';
import Message from '@/modules/core/components/Message.vue';
import Modal from '@/modules/core/modal/Modal.vue';

export default Vue.extend({
	name: 'App',
	components: { Loader, Message, Modal },
	created() {
		document.title = 'Zoet Zwanger';
	},
	data() {
		return {};
	},
	mounted() {
		window.addEventListener('resize', this.handleResize);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
	},
	computed: {},
	methods: {
		handleResize() {	// We execute the same script as before
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		},
	},
});
