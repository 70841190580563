









import Vue from 'vue';
import {getLocales} from "@/modules/core/utils";
import moment from "moment";

export default Vue.extend({
  name: 'LocalePicker',
  data() {
    return {
      currentLocale: this.$i18n.locale,
      locales: []
    };
  },
  mounted() {
    this.locales = this.loadLocales()
  },
  methods: {
    switchLocale(locale: string) {
      this.currentLocale = locale;

      this.$vuetify.lang.current = locale;
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
      }
      moment.locale(this.$i18n.locale)
    },
    loadLocales() {
      return getLocales().map(language => ({
        text: this.$t(`common.languages.${language}`),
        description: this.$t(`common.languages.continue-in.${language}`),
        value: language
      }));
    },

  },
  watch: {
    currentLocale() {
      this.switchLocale(this.currentLocale);
      this.locales = this.loadLocales()
    }
  }
});
