import './modules/core/plugins/vuetify';
import App from './modules/app/App.vue';
import i18n from './i18n/i18n';
import vuetify from './modules/core/plugins/vuetify';
import errorPlugin from './modules/core/plugins/ErrorPlugin';
import loaderPlugin from './modules/core/plugins/LoaderPlugin';
import formatPlugin from './modules/core/plugins/FormatPlugin';
import Vue from 'vue';
import { router } from '@/modules/core/router/router';
import { store } from '@/modules/core/stores/main-store';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import { detectMobile, getEnv } from '@/modules/core/utils';
import initData from '@/modules/core/stores/init-data';
import '@/modules/core/directives';

Vue.config.productionTip = false;

Vue.use(errorPlugin);
Vue.use(loaderPlugin);
Vue.use(formatPlugin);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.prototype.$isMobile = detectMobile;

// Remove all console log/error/warn/debug statements in production
if (getEnv('NODE_ENV') === 'production') {
	console.log = function () {};
	console.error = function () {};
	console.warn = function () {};
	console.debug = function () {};
}

initData();
initializeVue();

function initializeVue() {
	new Vue({
		router,
		store,
		i18n,
		vuetify,
		render: (h) => h(App),
	}).$mount('#app');
}
