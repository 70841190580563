import colors from 'vuetify/lib/util/colors'

const axians_purple = '#a30368';
const axians_blue = '#005eb8';

export enum Theme {
    AXIANS = 'Axians',
    CUSTOM = 'Custom'
};

const axiansTheme = {
    id: Theme.AXIANS,
    primary: '#00B1EB',
    primaryLight: '#00BBFAFF',
    secondary: axians_purple,
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107',
    axians_purple: '#a30368',
    axians_blue: '#005eb8',
    background: '#f7f8fb',
    grey4: '#BDBDBD',
    light: '#828282'
};

const customTheme = {
    id: Theme.CUSTOM,
    primary: colors.red,
    secondary: colors.blue,
    accent: colors.yellow,
    error: colors.amber,
    info: colors.brown,
    success: colors.deepOrange,
    warning: colors.purple,
    background: colors.lime,
};

export const getTheme = (theme: string) => {
    switch (theme) {
        case Theme.AXIANS:
            return axiansTheme;
        case Theme.CUSTOM:
            return customTheme;
        default:
            return axiansTheme;
    }
};

