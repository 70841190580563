import {getValueFromObject} from "@/modules/core/utils";
import {Gemeente} from "@/modules/gemeente/domain";

export class Huisarts {
    huisartsId: string;
    voornaam: string;
    naam: string;
    adres: string;
    gemeenteId: string;
    gemeente: Gemeente | null;


    constructor(doctor: any) {
        this.huisartsId = getValueFromObject(doctor, 'huisartsId', "")
        this.voornaam = getValueFromObject(doctor, 'voornaam', "")
        this.naam = getValueFromObject(doctor, 'naam', "")
        this.adres = getValueFromObject(doctor, "adres", "")
        this.gemeenteId = getValueFromObject(doctor, 'gemeenteId', "")
        this.gemeente = this.gemeenteId? new Gemeente(doctor.gemeente) : null;
    }

    get fullName(): string {
        return `${this.voornaam} ${this.naam}`
    }
}

