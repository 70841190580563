import {getValueFromObject} from "@/modules/core/utils";

export class Gemeente {
    gemeenteId: string;
    postcode: number;
    naam: string;


    constructor(municipality: any) {
        this.gemeenteId = getValueFromObject(municipality, 'gemeenteId', "")
        this.postcode = getValueFromObject(municipality, 'postcode', "")
        this.naam = getValueFromObject(municipality, 'naam', "");
    }
}
