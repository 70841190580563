import {AxiansForm, FormItem, formRules, SelectItem,} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import {Vrouw} from '@/modules/vrouw/domains';
import moment from 'moment';

export enum UserInfoCommunication {
	letterAndEmail = 'le',
	email = 'e',
}

export enum UserInfoStep{
	name = 'name',
	contact = 'contact',
	communication = 'communication',
	communication_email = 'communication_email',
}


export class UserInfoSteppersForm extends AxiansForm {
	voornaam: FormItem;
	naam: FormItem;
	geboortedatum: FormItem;

	email: FormItem;
	confirmEmail: FormItem;
	adres: FormItem;
	gemeenteId: FormItem;
	telefoon: FormItem;

	communicatie: FormItem;

	doubleEmail: FormItem;
	doubleConfirmEmail: FormItem;

	constructor(translator: VueI18n, initialData?: Vrouw) {
		super();

		this.voornaam = new FormItem(
			'firstName',
			'userInfo.firstName',
			initialData?.voornaam ?? '',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.naam = new FormItem(
			'lastName',
			'userInfo.lastName',
			initialData?.naam ?? '',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.geboortedatum = new FormItem(
			'birthday',
			'userInfo.birthday',
			initialData?.geboortedatum?.format('YYYY-MM-DD') ?? null,
			formRules.geboorteDatumValidatie(translator)
		);

		this.email = new FormItem(
			'email',
			'userInfo.email',
			initialData?.email ?? '',
			formRules.isEmail(translator, 'form.email')
		);

		this.confirmEmail = new FormItem(
			'confirmEmail',
			'userInfo.confirmEmail',
			initialData?.email ?? '',
			formRules.isEmail(translator, 'form.email')
		);

		this.adres = new FormItem(
			'streetAndHouseNumber',
			'userInfo.streetAndHouseNumber',
			initialData?.adres ?? '',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.gemeenteId = new FormItem(
			'city',
			'userInfo.city',
			initialData?.gemeenteId ?? '',
			formRules.required(translator, 'form.required')
		);

		this.telefoon = new FormItem('telefoon', 'userInfo.telefoon', initialData?.telefoon ?? '');

		this.communicatie = new FormItem(
			'communication',
			'',
			'',
			formRules.required(translator, 'form.required'),
			-1,
			[
				new SelectItem('userInfo.communication.email', UserInfoCommunication.email),
				new SelectItem(
					'userInfo.communication.letterAndEmail',
					UserInfoCommunication.letterAndEmail
				),
			]
		);

		this.doubleEmail = new FormItem(
			'doubleEmail',
			'userInfo.doubleConfirmEmail.email',
			initialData?.email ?? '',
			formRules.isEmailAndRequired(translator, 'form.email')
		);

		this.doubleConfirmEmail = new FormItem(
			'doubleEmail',
			'userInfo.doubleConfirmEmail.confirmEmail',
			'',
			formRules.isEmailAndRequired(translator, 'form.email')
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			voornaam: this.voornaam.value,
			naam: this.naam.value,
			email: this.email.value || this.doubleEmail.value,
			geboortedatum: moment(this.geboortedatum.value).format('YYYY-MM-DD'),
			adres: this.adres.value,
			gemeente: this.gemeenteId.value,
			telefoon: this.telefoon.value,
			voorkeurEmail: this.communicatie.value === UserInfoCommunication.email,
		};
	}
}
