export class Route {
    name: string;
    publicRoute: boolean;

    constructor(name: string, publicRoute: boolean) {
        this.name = name;
        this.publicRoute = publicRoute;
    }
}

export const ROUTES = {
    HOME: new Route("home", true),
}
