<template>
	<v-row :class='marginValue' ref='rootRef'>
		<div class='on-the-side' v-if='showBigBackBtn && !noGoingBack' @click="$emit('goPrev')">
			<svg width='85' height='423' viewBox='0 0 85 423' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path d='M0 0C89.5693 72.0114 134.857 326.451 0 423V0Z' fill='#F7F7F7' />
				<path d='M49 186.275L25 206.104L49 225.932' stroke='#E0E0E0' stroke-width='7' stroke-linecap='round' />
			</svg>
		</div>
		<v-col :cols='bigText ? 12: 6' v-if='!showBigBackBtn && !noGoingBack'>
			<v-btn large width='100%' type='button' @click="$emit('goPrev')">
				{{ $t(prevText) }}
			</v-btn>
		</v-col>

		<v-col :cols='colsNext' v-if="!noGoingNext">
			<v-btn class='primary' large width='100%' type='submit'>
				{{ $t(nextText) }}
			</v-btn>
		</v-col>
	</v-row>


</template>

<script>
export default {
	name: 'ActionContainer',
	props: {
		noGoingBack: Boolean,
		noGoingNext: Boolean,
		extraMargin: Boolean,
		bigText: Boolean,
		stepper: Number,
		prevText: {
			type: String,
			default: 'registration.stepper.actions.prev',
		},
		nextText: {
			type: String,
			default: 'registration.stepper.actions.next',
		},
	},
	data() {
		return {
			showBigBackBtn: false,
		};
	},
	computed: {
		marginValue() {
			return this.extraMargin ? 'mt-4' : 'mt-0';
		},
		colsNext() {
			if (this.bigText) {
				return 12;
			}

			if (!this.showBigBackBtn && !this.noGoingBack) {
				return 6;
			}

			return 12;
		},
	},
	created() {
		window.addEventListener('resize', this.calcGoingBack);
	},
	mounted() {
		this.calcGoingBack();
	},
	destroyed() {
		window.removeEventListener('resize', this.calcGoingBack);
	},
	methods: {
		calcGoingBack() {
			const rootRef = this.$refs.rootRef;

			if (!rootRef) {
				this.showBigBackBtn = false;
				return;
			}

			const stepperContent = rootRef.closest('.v-stepper__content');
			const stepperWrapper = rootRef.closest('.v-stepper__wrapper');

			if (!stepperContent || !stepperWrapper) {
				this.showBigBackBtn = false;
				return;
			}

			const diff = stepperContent.clientWidth - stepperWrapper.clientWidth;

			this.showBigBackBtn = diff >= 200;
		},
	},
	watch: {
		stepper() {
			this.calcGoingBack();
		},
	},
};
</script>

<style scoped lang='scss'>
.on-the-side {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
}
</style>
