import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/modules/home/Home.vue';
import { ROUTES } from '@/modules/core/router/routes';
import Notfound from '@/modules/notfound/404.vue';

Vue.use(Router);

export const router = new Router({
	mode: 'history',
	// base: Configuration.value("baseUrl"),
	routes: [
		{
			path: '/',
			name: ROUTES.HOME.name,
			component: Home,
			meta: {
				isPublic: ROUTES.HOME.publicRoute,
				sidebar: false,
			},
		},
		{
			path: '*',
			component: Notfound,
		},
	],
});
