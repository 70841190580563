import {Vrouw} from "@/modules/vrouw/domains";
import {getValueFromObject} from "@/modules/core/utils";

export enum OpvolgingType {
    M3 = "3m",
    J1 = "1j",
    J2 = "2j",
    J3 = "3j",
    J4 = "4j",
    J5 = "5j",
    J6 = "6j",
    J7 = "7j",
    J8 = "8j",
    J9 = "9j",
    J10 = "10j",
    nietGekend = "niet_gekend"
}

export function getOpvolgingType(type: string): OpvolgingType {
    switch (type) {
        case "3m":
            return OpvolgingType.M3;
        case "1j":
            return OpvolgingType.J1;
        case "2j":
            return OpvolgingType.J2;
        case "3j":
            return OpvolgingType.J3;
        case "4j":
            return OpvolgingType.J4;
        case "5j":
            return OpvolgingType.J5;
        case "6j":
            return OpvolgingType.J6;
        case "7j":
            return OpvolgingType.J7;
        case "8j":
            return OpvolgingType.J8;
        case "9j":
            return OpvolgingType.J9;
        case "10j":
            return OpvolgingType.J10;
        default:
            return OpvolgingType.nietGekend
    }
}

export class Opvolging {
    opvolgingId: string;
    opvolgingType: OpvolgingType;

    constructor(data: any) {
        this.opvolgingId = getValueFromObject(data, 'opvolgingId', '')
        this.opvolgingType = getOpvolgingType(data.opvolgingType)
    }

}

export class OpvolgingVrouw {
    opvolging: Opvolging;
    vrouw: Vrouw;

    constructor(data: any) {
        this.opvolging = new Opvolging(data)
        this.vrouw = new Vrouw(data.vrouw)
    }

    get opvolgingTypeSwitch(): "3m" | "xm" | null {
        if (this.opvolging.opvolgingType === OpvolgingType.nietGekend) {
            return null
        }

        if (this.opvolging.opvolgingType === OpvolgingType.M3) {
            return "3m"
        }

        return "xm"
    }
}