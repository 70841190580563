import { httpService } from '@/modules/core/services/http-service';
import { Opvolging, OpvolgingVrouw } from '@/modules/home/domains';
import { Vrouw } from '@/modules/vrouw/domains';
import { AxiansError } from '@/modules/core/errors/errors.domain';

// REGISTRATIE

export async function postOnlineRegistration(data: any): Promise<void> {
	await httpService.post<any>(`/online-registratie/api/voorlopige-registratie`, data);
}

// OPVOLGING

export async function getOnlineOpvolging(
	opvolgingId: string,
	hash: string
): Promise<OpvolgingVrouw> {
	const response = await httpService.get(`/online-feedback/api/${opvolgingId}?hash=${hash}`);
	return new OpvolgingVrouw(response);
}

export async function saveOpvolging(opvolging: Opvolging, data: any, hash: string): Promise<void> {
	await httpService.put(`/online-feedback/api/${opvolging.opvolgingId}?hash=${hash}`, data);
}

// UITSCHRIJVEN

export async function getVrouwDoorId(stopzettingId: string, hash: string): Promise<Vrouw> {
	const response = await httpService.get<any>(
		`/online-uitschrijven/api/${stopzettingId}?hash=${hash}`
	);
	const vrouw = new Vrouw(response);

	if (!vrouw.vrouwId) {
		return Promise.reject([new AxiansError({},404)]);
	}

	return vrouw;
}

export async function schrijfVrouwUit(vrouwId: string, hash: string): Promise<void> {
	await httpService.post<any>(`/online-uitschrijven/api/${vrouwId}?hash=${hash}`, {});
}
