<template>
    <v-card>
        <v-card-title>
            <p class='mb-0 flow-title'>{{ $t('3m.title') }} </p>
            <v-spacer/>
            <p class='mb-0'>{{ userName }}</p>
        </v-card-title>

        <v-progress-linear :value='progressValue'/>

        <v-stepper v-model='headerStepper'>
            <v-stepper-header>
                <v-stepper-step :complete='headerStepper > 1' :step='1'>{{
                    $t('3m.stepper.headers.step1')
                    }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete='headerStepper > 2' :step='2'>{{
                    $t('3m.stepper.headers.step2')
                    }}
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete='headerStepper > 3' :step='3'>{{
                    $t('3m.stepper.headers.step3')
                    }}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper v-model='stepper'>
                <v-stepper-items>

                    <v-stepper-content step='1'>
                        <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                            {{ $t('3m.stepper.form.deliveryDate.title', {firstName: firstName}) }}</p>
                        <v-form ref='deliveryDate' @submit.prevent='goNextDeliveryDate()'>
                            <date-field v-model='form.uiteindelijkeBevallingsDatum.value'
                                        :label='$t(form.uiteindelijkeBevallingsDatum.label, {firstName: firstName})'
                                        :rules='form.uiteindelijkeBevallingsDatum.rules'
                            />
                            <action-container no-going-back extra-margin :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step='2'>
                        <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                            {{ $t('3m.stepper.form.control.title') }}
                        </p>
                        <v-form @submit.prevent='goNextControl()'>
                            <v-card v-for='item in form.bloedglucosecontrole.selectItems'
                                    :key='item.value'
                                    class="card-option"
                                    :class="{'card-option--selected': isActive(item.value, form.bloedglucosecontrole.value)}"
                                    @click='handleControlClick(item)'>
                                <span>{{ $t(item.text) }}</span>
                            </v-card>
                            <p class='error--text' v-if='controlError'>{{ $t('3m.stepper.form.control.required') }}</p>
                            <action-container @goPrev='goPrev' no-going-next big-text :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <v-stepper-content step='3'>
                        <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
                            {{ $t('3m.stepper.form.diabetic.title') }}
                        </p>
                        <v-form @submit.prevent='goNextDiabetic'>
                            <div class="d-flex justify-center">
                                <v-card v-for='(item, idx) in form.diabetis.selectItems'
                                        :key='item.value'
                                        class="card-option card-option--vertical"
                                        :class="{'card-option--selected': isActive(item.value, form.diabetis.value)}"
                                        @click='handleDiabeticClick(item)'>

                                    <v-icon large color="primary">
                                        {{ idx === 0 ? 'done' : 'close' }}
                                    </v-icon>
                                    <span>{{ $t(item.text) }}</span>
                                </v-card>
                            </div>
                            <p class='error--text' v-if='diabeticError'>{{ $t('3m.stepper.form.diabetic.required') }}</p>
                            <action-container @goPrev='goPrev' no-going-next big-text :stepper='stepper'/>
                        </v-form>
                    </v-stepper-content>

                    <v-stepper-content step='4'>
                        <v-form @submit.prevent='goNext()'>
                            <p class='text-h5 mx-auto mb-6 font-weight-bold' style='white-space: pre-wrap'>
                                {{ $t('diabeticConfirmed.title') }}
                            </p>
                            <p style='white-space: pre-wrap'>{{ $t('diabeticConfirmed.description') }}</p>

                            <action-container @goPrev='goPrev' :stepper='stepper'/>
                        </v-form>

                    </v-stepper-content>

                    <user-info-steppers :stepper='stepper'
                                        :begin-stepper='5'
                                        :vrouw='vrouw'
                                        @goNext='goNextUserInfo'
                                        @goNextBeforeCommunication='goNextUserInfoBeforeCommunication'
                                        @goPrev='goPrev'/>

                    <v-stepper-content :step='lastStepNumber' class='thanks' v-if='!hasSubmitError'>
                        <v-icon size='200' color='green'>check_circle</v-icon>
                        <p class='text-h5 my-4'>{{ $t('3m.stepper.thanks.title', {name: firstName}) }}</p>
                        <p class='light--text my-4'>{{ $t('3m.stepper.thanks.description') }}</p>
                    </v-stepper-content>

                    <v-stepper-content :step='lastStepNumber' class='thanks' v-else>
                        <v-icon size='200' color='error'>error</v-icon>
                        <p class='text-h5 my-4 text-center'>{{ $t('3m.stepper.thanks_error.title', {name: firstName}) }}</p>
                        <p class='light--text my-4 text-center'>{{ $t('3m.stepper.thanks_error.description') }}</p>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>

        </v-stepper>
        <home-back-button-handler :step='stepper' @popstate='goPrev'/>
    </v-card>
</template>

<script>
import LocalePicker from '@/modules/core/components/LocalePicker';
import ActionContainer from '@/modules/home/ActionContainer';
import {DiabetisOptions, M3Form} from '@/modules/home/forms/3m';
import UserInfoSteppers from '@/modules/home/userInfoSteppers';
import DateField from '@/modules/core/components/forms/DateField';
import {Vrouw} from '@/modules/vrouw/domains';
import {Opvolging} from '@/modules/home/domains';
import {UserInfoSteppersForm} from '@/modules/home/forms/userInfoSteppersForm';
import {saveOpvolging} from '@/modules/home/api';
import HomeBackButtonHandler from '@/modules/home/HomeBackButtonHandler.vue';

export default {
    name: 'm3',
    components: {HomeBackButtonHandler, DateField, UserInfoSteppers, ActionContainer, LocalePicker},
    props: {
        vrouw: Vrouw,
        opvolging: Opvolging,
        hash: String,
    },
    data() {
        return {
            stepper: 1,
            headerStepper: 1,
            form: new M3Form(this.$i18n),
            userInfoForm: new UserInfoSteppersForm(this.$i18n),
            controlError: false,
            diabeticError: false,
            stepperFollowList: [],
            hasSubmitError: false,
            lastStepNumber: 9
        };
    },
    mounted() {
    },
    computed: {
        firstName() {
            return this.vrouw?.voornaam ?? '';
        },
        userName() {
            return this.vrouw?.fullName ?? '';
        },
        progressValue() {
            return (this.stepper / this.lastStepNumber) * 100;
        },
    },
    methods: {
        goPrev() {
            this.stepper = this.stepperFollowList.pop();
        },
        goNext(form) {
            if (!form || form.validate()) {
                this.stepperFollowList.push(this.stepper);
                this.stepper += 1;
            }
        },
        goNextDeliveryDate() {
            this.form.uiteindelijkeBevallingsDatum.isSubmitted = true;
            this.$nextTick(() => {
                this.goNext(this.$refs.deliveryDate);
            });
        },
        goNextControl() {
            this.controlError = Boolean(!this.form.bloedglucosecontrole.value);

            if (!this.controlError) {
                this.goNext();
            }

        },
        goNextDiabetic() {
            this.diabeticError = Boolean(!this.form.diabetis.value);

            if (!this.diabeticError) {
                if (this.form.diabetis.value === DiabetisOptions.YES) {
                    this.goNext();
                } else {
                    this.stepperFollowList.push(this.stepper);
                    this.stepper += 2;
                }
            }
        },
        goNextUserInfo(form, userInfoForm) {
            this.userInfoForm = userInfoForm;
            this.goNext(form);
        },
        goNextUserInfoBeforeCommunication(form, userInfoForm) {
            this.goNextUserInfo(form, userInfoForm);

            if (!form || form.validate()) {
                if (this.form.diabetis.value === DiabetisOptions.YES) {
                    this.stepper++;
                    this.stepper++;
                }
            }
        },
        isActive(itemValue, formValue) {
            return itemValue === formValue;
        },
        getIcon(isActive) {
            return isActive ? 'done' : 'close';
        },
        getClass(isActive) {
            let output = 'my-6 py-4 px-2';

            if (isActive) {
                output = `${output} control-active`;
            }

            return output;
        },
        handleControlClick(item) {
            this.form.bloedglucosecontrole.value = item.value;
            this.controlError = false;
            this.goNextControl();
        },
        handleDiabeticClick(item) {
            this.form.diabetis.value = item.value;
            this.diabeticError = false;
            this.goNextDiabetic();
        },
        async handleSubmit() {
            try {
                await saveOpvolging(this.opvolging, {...this.form.toJSON(), ...this.userInfoForm.toJSON()}, this.hash);
            } catch (e) {
                this.hasSubmitError = true;
            }
        },
    },
    watch: {
        stepper() {
            if (this.stepper >= this.lastStepNumber) {
                this.handleSubmit();
            }

            const stepper2Begin = 2;
            const stepper3Begin = 5;
            const stepper4Begin = this.lastStepNumber;

            if (stepper4Begin <= this.stepper) {
                this.headerStepper = 4;
                return;
            }

            if (stepper3Begin <= this.stepper) {
                this.headerStepper = 3;
                return;
            }

            if (stepper2Begin <= this.stepper) {
                this.headerStepper = 2;
                return;
            }

            this.headerStepper = 1;
        },
    },
};
</script>

<style scoped lang='scss'>
::v-deep .v-stepper__wrapper {
  max-width: 500px;
  margin: auto;

  @media screen and (min-width: 600px) {
    //min-height: 400px;
  }
}

.control-active {
  border: solid 2px var(--v-primary-base);
  background-color: var(--v-primary-base);
  color: white;
}

.full-width {
  ::v-deep .v-stepper__wrapper {
    max-width: 600px;
  }
}

::v-deep a {
  color: var(--v-grey4-base) !important;
}

.thanks {
  ::v-deep .v-stepper__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
</style>
