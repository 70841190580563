import { AxiansForm, FormItem, formRules } from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';

export class RegistrationMetricsForm extends AxiansForm {
	lengte: FormItem;
	gewicht: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.lengte = new FormItem(
			'height',
			'registration.stepper.metrics.height',
			'',
			formRules.numberBetween(translator, 0.5, 3)
		);

		this.gewicht = new FormItem(
			'weight',
			'registration.stepper.metrics.weight',
			'',
			formRules.numberBetween(translator, 25, 500)
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			heightBefore: this.lengte.value,
			weightBefore: this.gewicht.value,
		};
	}
}
