import Vue from 'vue';

const NUMBERS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ','];


export const onlyNumberAllowed = (e: KeyboardEvent) => {
    if (!NUMBERS.includes(e.key)) {
        e.preventDefault();
    }
}

const handleInputChange = (e: KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    target.value = target.value.replaceAll(',', '.');
};

Vue.directive('input-decimal-converter', {
    bind: (el: HTMLElement) => {
        const elInput: HTMLInputElement | null = el.querySelector('input');

        if (!elInput) {
            return;
        }

        elInput.setAttribute('type', 'text');
        elInput.setAttribute('pattern', '[0-9,.]*');
        elInput.setAttribute('inputmode', 'decimal');

        elInput.onkeyup = handleInputChange;
    },
});
Vue.directive('input-integer-converter', {
    bind: (el: HTMLElement) => {
        const elInput: HTMLInputElement | null = el.querySelector('input');

        if (!elInput) {
            return;
        }

        elInput.setAttribute('type', 'text');
        elInput.setAttribute('pattern', '[0-9]*');
        elInput.setAttribute('inputmode', 'numeric');

        elInput.onkeyup = handleInputChange;
    },
});
