<template>
</template>

<script>


export default {
	name: 'HomeBackButtonHandler',
	props: {
		step: Number,
	},
	data() {
		return {};
	},
	mounted() {
		window.addEventListener('popstate', this.handlePopstate);
	},
	beforeDestroy() {
		window.removeEventListener('popstate', this.handlePopstate);
	},
	methods: {
		changeQueryString() {
			const url = new URL(window.location.href);

			if (this.step === 1) {
				url.searchParams.delete('step');
			} else {
				url.searchParams.set('step', this.step);
			}

			const obj = { Title: document.title, Url: url.href };
			history.pushState(obj, obj.Title, obj.Url);
		},
		handlePopstate() {
			if (this.step !== 1) {
				this.$emit('popstate');
			}
		},
	},
	watch: {
		step() {
			this.changeQueryString();
		},
	},
};
</script>

<style scoped>

</style>