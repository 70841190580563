<template>
    <v-snackbar
            v-model="$message.show"
            :bottom="y === 'bottom'"
            :color="$message.color"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="timeout"
            :top="y === 'top'"
            :vertical="mode === 'vertical'"
    >
        {{ $message.text }}
        <v-btn
                dark
                text
                @click="$hideMessage"
        >
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>
    export default {
        name: "Message",
        data() {
            return {
                mode: '',
                timeout: 6000,
                x: 'right',
                y: 'bottom',
            }
        }
    }
</script>

<style scoped>

</style>