import {
	AxiansForm,
	FormItem,
	formRules,
	SelectItem,
} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';

export enum NumberResultOptions {
	NORMAL = 'N',
	CRAZY = 'GNG',
	DIABETIC = 'DM',
	NIET_GEKEND = '',
}

export enum ConsultatieHuisartsType {
	Ja = 'j',
	Nee = 'n',
	NeeMaarGepland = 'gepland',
	NeeWantZwanger = 'zwanger',
	GeenResultaat = 'geen_result',
}

export enum BloedglucosecontroleOptions {
	YES = 'yes',
	NO = 'no',
}

export class XmForm extends AxiansForm {
	huisartsAfspraak: FormItem;
	bloedglucosecontrole: FormItem;
	bloedsuikerwaarden: FormItem;
	bloedsuikerwaardenOpties: FormItem;
	extraCommentaar: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.huisartsAfspraak = new FormItem(
			'control',
			'',
			'',
			formRules.required(translator, 'form.required'),
			-1,
			[
				new SelectItem('xm.stepper.form.control.options.yes', ConsultatieHuisartsType.Ja),
				new SelectItem(
					'xm.stepper.form.control.options.planned',
					ConsultatieHuisartsType.NeeMaarGepland
				),
				new SelectItem(
					'xm.stepper.form.control.options.pregnant',
					ConsultatieHuisartsType.NeeWantZwanger
				),
				new SelectItem('xm.stepper.form.control.options.no', ConsultatieHuisartsType.Nee),
			]
		);

		this.bloedglucosecontrole = new FormItem(
			'bloodControl',
			'xm.stepper.form.bloodControl.label',
			'',
			formRules.required(translator, 'form.required'),
			-1,
			[
				new SelectItem('xm.stepper.form.bloodControl.options.yes', BloedglucosecontroleOptions.YES),
				new SelectItem('xm.stepper.form.bloodControl.options.no', BloedglucosecontroleOptions.NO),
			]
		);

		this.bloedsuikerwaarden = new FormItem(
			'resultNumber',
			'xm.stepper.form.result.number.label',
			'',
			formRules.numberBetween(translator, 50, 300)
		);

		this.bloedsuikerwaardenOpties = new FormItem(
			'resultSelection',
			'xm.stepper.form.result.selection.label',
			'',
			undefined,
			-1,
			[
				new SelectItem('', NumberResultOptions.NORMAL),
				new SelectItem('', NumberResultOptions.CRAZY),
				new SelectItem('', NumberResultOptions.DIABETIC),
			]
		);

		this.extraCommentaar = new FormItem(
			'resultNumber',
			'xm.stepper.form.resultComment.label',
			'',
			formRules.maxChars(translator, 1000)
		);
	}

	toJSON(): { [p: string]: any } {
		const controleGedaan =
			this.bloedglucosecontrole.value === BloedglucosecontroleOptions.YES &&
			this.huisartsAfspraak.value === ConsultatieHuisartsType.Ja;

		return {
			bloedglucoseWaarde: controleGedaan ? Number.parseInt(this.bloedsuikerwaarden.value) : null,
			bloedglucoseResultaat: controleGedaan ? this.bloedsuikerwaardenOpties.value : null,
			consultatieHuisarts: this.huisartsAfspraak.value,
			comment: this.extraCommentaar.value,
		};
	}
}
