



























































import moment from 'moment';
import Vue from 'vue';

export default Vue.extend({
	name: 'DateField',
	props: {
		value: {
			type: String, // of format: 'yyyy-MM-DD'
			default: '',
		},
		label: {
			type: String,
			required: true,
		},
		rules: {
			type: Array,
			required: false,
			default: () => [],
		},
		allowedDates: {
			type: Function,
			default: () => () => true,
		},
		readonly: {
			type: Boolean,
			default: () => false,
		},
		hideBorders: {
			type: Boolean,
			default: () => false,
		},
    noDatePicker: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false

    }
	},
	mounted() {
		this.updateInternalDate()
	},
	computed: {
		internalDate: {
			get(): string {
				return this.value;
			},
			set(newValue): void {
				const menu: any = this.$refs.menu;
				if (menu && menu.save) {
					menu.save(newValue);
				}
				this.$emit('input', newValue);

				this.updateInternalDate(newValue)
			},
		},
		locale() {
			// @ts-ignore
			return this.$vuetify.lang.current;
		},
	},
	data() {
		return {
			menu: false,
			formattedDate: '',
		};
	},
	methods: {
		handleDateChange() {
			const momentDate = moment(this.formattedDate, 'DD-MM-YYYY', true);
			if (momentDate.isValid()) {
				this.internalDate = momentDate.format('YYYY-MM-DD');
			}
		},
		updateInternalDate(internalDate: any = null) {

			if(!internalDate) {
				internalDate = this.internalDate
			}

			if (internalDate) {
				// @ts-ignore
				this.formattedDate = moment(internalDate).format('DD-MM-yyyy');
			}
		},
		onClear() {
			// @ts-ignore
			this.internalDate = '';
		},
		onChange(updateDate: string) {
			this.formattedDate = updateDate;
			this.handleDateChange();
		},
	},
});
