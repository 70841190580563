import {Module} from "vuex";
import {getAllMunicipalitiesWithNoLimits} from "@/modules/gemeente/api";
import {Gemeente} from "@/modules/gemeente/domain";

export default (): Module<any, any> => ({
    namespaced: true,
    state: {
        gemeenten: []
    },
    mutations: {
        SET_GEMEENTEN(state, route) {
            state.gemeenten = route;
        }
    },
    getters: {
        getGemeenteById: (state) => (id: string): Gemeente | undefined => {
            return state.gemeenten.find((gemeente: Gemeente) => gemeente.gemeenteId === id)
        }
    },
    actions: {
        async fetchGemeenten(context) {
            try {
                const response = await getAllMunicipalitiesWithNoLimits();
                context.commit('SET_GEMEENTEN', response)
            } catch (e) {

            }

        }
    }
})