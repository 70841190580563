<template>

	<p v-if='readonly' class='mb-0'>
		{{ readonlyText }}
	</p>

	<div v-else>
		<v-autocomplete
			ref='autocomplete'
			:outlined='!isNotOutlined'
			:items='itemsToDisplay'
			:rules='rules'
			:label='$t(label)'
			:value='value'
      :filter="filterFn"
			:search-input.sync='searchInput'
			:menu-props='{closeOnContentClick: true}'
      auto-select-first
			:no-data-text='noDataText'
			:clearable='clearable'
			@input='handleInput'
      :autofocus="autofocus"
		>

			<template v-if='Boolean(canCustom)' v-slot:prepend-item>
				<v-list-item
					ripple
					@mousedown.prevent
					@click='handleCustom'
				>
					<v-list-item-content>
						<v-list-item-title>
							{{ $t(`default-select.custom-${type}`) }}
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider class='mt-2' />
			</template>

		</v-autocomplete>
	</div>


</template>

<script>
import {mapGetters, mapState} from 'vuex';
import {getAlleDiabetesteams} from '@/modules/diabetesteam/api';
import {getAlleHuisartsen} from '@/modules/huisarts/api';

export default {
	name: 'defaultSelect',
	props: {
		value: {
			type: String,
			required: true,
		},
		rules: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
		readonly: {
			type: Boolean,
			required: false,
		},
		clearable: {
			type: Boolean,
			required: false,
		},
		isNotOutlined: {
			type: Boolean,
			default: () => false,
		},
		type: {
			type: String,
			required: true,
		},
		canCustom: {
			type: Boolean,
			required: false,
		},
    autofocus: {
      type: Boolean,
      required: false,
      default: false

    }
	},
	data() {
		return {
			searchInput: '',
			items: [],
			itemsToDisplay: [],
		};
	},
	mounted() {
		this.handleTypeChange();
	},
	computed: {
		...mapState('gemeenten', ['gemeenten']),
		...mapGetters('gemeenten', ['getGemeenteById']),
		readonlyText() {
			const item = this.items.find(item => item.value === this.value);
			return item?.text ?? '';
		},
		isDoctor() {
			return this.type === 'doctor';
		},
		isCity() {
			return this.type === 'city';
		},
		isDiabeticTeam() {
			return this.type === 'diabeticTeam';
		},
		shouldWaitForDebounceInput() {
			return this.isCity && (this.searchInput || '').length < 3;
		},
		noDataText() {
			if (this.shouldWaitForDebounceInput) {
				return this.$t('default-select.debounce-search');
			}

			return this.$t('default-select.no-data-available');
		},
	},
	methods: {
    filterFn(item, queryText, itemText) {
      if(this.isCity && isNaN(queryText)) {
          return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase());
      } else {
        return itemText.toLocaleLowerCase().includes(queryText.toLocaleLowerCase());
      }
    },
		handleInput(item) {
			this.$emit('input', item || '');
		},
		async handleTypeChange() {
			if (this.isDoctor) {
				const doctors = await getAlleHuisartsen();

				this.items = doctors.map(doctor => {
					return {
						value: doctor.huisartsId,
						text: `${doctor.fullName} - ${doctor.gemeente.naam}`,
					};
				});
			}

			if (this.isDiabeticTeam) {
				const diabeticTeams = await getAlleDiabetesteams();
				this.items = diabeticTeams.map(dt => ({ value: dt.diabetesteamId, text: dt.naam }));
			}

			if (this.isCity) {
				this.items = this.gemeenten.map(city => ({ value: city.gemeenteId, text: `${city.naam} (${city.postcode})` }));
			}

			this.setItemsToDisplay();
		},
		handleCustom() {
			this.handleInput('custom');
		},
		setItemsToDisplay() {
			if (this.shouldWaitForDebounceInput) {
				this.itemsToDisplay = this.items.filter(item => item.value === (this.value || ''));
			} else {
				this.itemsToDisplay = this.items.filter(item => item.text.toLowerCase().includes((this.searchInput || '').toLowerCase() || ''));
			}
		},
	},
	watch: {
		type() {
			this.handleTypeChange();
		},
		gemeenten() {
			this.handleTypeChange();
		},
		searchInput() {
			this.setItemsToDisplay();
		},
	},
};
</script>

<style scoped lang='scss'>
</style>
