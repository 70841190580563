<template>
  <vue-tel-input-vuetify :value="value"
                         :rules="telefoonRules"
                         :label="label"
                         :only-countries="['be']"
                         :input-options="{showDialCode: false}"
                         placeholder=""
                         outlined
                         mode="national"
                         validate-on-blur
												 default-country='be'
                         @validate="handlePhoneValidate"
                         @change="$emit('input', $event)"
  />
</template>

<script>
export default {
  name: "PhoneField",
  props: ["value", "label"],
  data() {
    return {
      telefoonValidObject: null
    }
  },
  computed: {
    telefoonRules() {
			if (!this.telefoonValidObject || !this.value || this.value.toString() === `+${this.telefoonValidObject.country.dialCode}`) {
        return [true]
      }

      if (!this.telefoonValidObject.isValid) {
        return [this.$t('form.telefoonValid')]
      }

      return [true]
    }
  },
  methods: {
    handlePhoneValidate(validate) {
      this.telefoonValidObject = validate;
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep.vue-tel-input-vuetify {
  .country-code {
    display: none;
  }
}
</style>