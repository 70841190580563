export class AxiansError {
    public message: ValidationError;
    public code: number;

    constructor(message: ValidationError, code?: number) {
        this.message = message;
        this.code = code || 400;
    }
}

export type ValidationError = {
    code?: ValidationCode;
    field?: string;
    rejectedValue?: string;
    message?: string;
}


export enum ValidationCode {
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
    FAILED_CREATE_TODO = 'FAILED_CREATE_TODO',
    FAILED_DELETE_TODO = 'FAILED_DELETE_TODO',
    FAILED_UPDATE_TODO = 'FAILED_UPDATE_TODO',
    FAILED_START_TODO = 'FAILED_START_TODO',
    FAILED_STOP_TODO = 'FAILED_STOP_TODO',
}
