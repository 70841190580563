<template>
  <v-card>
    <v-card-title>
      <p class='mb-0 flow-title'>{{ $t('registration.title') }} </p>
      <v-spacer/>
      <p class='mb-0'>{{ userName }}</p>
    </v-card-title>

    <v-progress-linear height="6" :value='progressValue'/>

    <v-stepper v-model='headerStepper'>
      <v-stepper-header>
        <v-stepper-step :complete='headerStepper > 1' :step='1'>{{
            $t('registration.stepper.headers.step1')
          }}
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete='headerStepper > 2' :step='2'>{{
            $t('registration.stepper.headers.step2')
          }}
        </v-stepper-step>
        <v-divider/>
        <v-stepper-step :complete='headerStepper > 3' :step='3'>{{
            $t('registration.stepper.headers.step3')
          }}
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper v-model='stepper'>
        <v-stepper-items>
          <v-stepper-content step='1'>
            <v-form @submit.prevent.prevent='goNext()'>
              <h2 class="text-h3">{{ $t('registration.stepper.welcome.title') }}</h2>
              <p class='my-8'>{{ $t('registration.stepper.welcome.description') }}</p>
              <locale-picker/>
              <action-container no-going-back no-going-next extra-margin :stepper='stepper'/>
            </v-form>

          </v-stepper-content>

          <v-stepper-content step='2'>
            <v-form ref='personal' @submit.prevent='goNext($refs.personal, forms.personal)'>
              <v-text-field v-model='forms.personal.voornaam.value'
                            autofocus
                            :label='$t(forms.personal.voornaam.label)'
                            :rules='forms.personal.voornaam.rules'
                            outlined
              />

              <v-text-field v-model='forms.personal.naam.value'
                            :label='$t(forms.personal.naam.label)'
                            :rules='forms.personal.naam.rules'
                            outlined
              />

              <date-field v-model='forms.personal.geboortedatum.value'
                          :label='forms.personal.geboortedatum.label'
                          :rules='forms.personal.geboortedatum.rules'
                          no-date-picker
              />

              <action-container :stepper='stepper' @goPrev='goPrev(forms.personal)'/>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step='3'>
            <v-form ref='contact' @submit.prevent='goNext($refs.contact, forms.contact)'>
              <p class='text-h5 mx-auto mb-12'>{{ $t('registration.stepper.contact.title', {name: firstName}) }}</p>
              <v-text-field v-model='forms.contact.email.value'
                            autofocus
                            :label='$t(forms.contact.email.label)'
                            :rules='forms.contact.email.rules'
                            outlined
                            type='email'
              />
              <v-text-field v-model='forms.contact.confirmEmail.value'
                            :label='$t(forms.contact.confirmEmail.label)'
                            :rules='confirmEmailRule'
                            outlined
                            type='email'
              />
              <v-text-field v-model='forms.contact.adres.value'
                            :label='$t(forms.contact.adres.label)'
                            :rules='forms.contact.adres.rules'
                            outlined
              />
              <default-select type='city' v-model='forms.contact.gemeenteId.value'
                              :label='forms.contact.gemeenteId.label'
                              :rules='forms.contact.gemeenteId.rules'
              />
              <phone-field v-model='forms.contact.telefoon.value'
                           :label='$t(forms.contact.telefoon.label)'
              />
              <action-container :stepper='stepper' @goPrev='goPrev(forms.contact)'/>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step='4'>
            <v-form ref='delivery' @submit.prevent='goNext($refs.delivery, forms.delivery)'>
              <p class='text-h5 mx-auto mb-12'>{{ $t('registration.stepper.delivery.title') }}</p>
              <date-field v-model='forms.delivery.vermoedelijkeBevallingsDatum.value'
                          :label='forms.delivery.vermoedelijkeBevallingsDatum.label'
                          :rules='forms.delivery.vermoedelijkeBevallingsDatum.rules'
                          autofocus
              />
              <action-container :stepper='stepper' @goPrev='goPrev(forms.delivery)'/>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step='5'>
            <v-form ref='metrics' @submit.prevent='goNext($refs.metrics, forms.metrics)'>
              <p class='text-h5 mx-auto mb-12'>{{ $t('registration.stepper.metrics.title') }}</p>
              <v-text-field v-model='forms.metrics.lengte.value'
                            autofocus
                            :label='$t(forms.metrics.lengte.label)'
                            :rules='forms.metrics.lengte.rules'
                            outlined
                            v-input-decimal-converter
                            @keypress="onlyNumbersAllowed"
              >
                <template v-slot:append>
                  <span class='grey4--text'>{{ $t('registration.stepper.metrics.heightUnit') }}</span>
                </template>
              </v-text-field>
              <v-text-field v-model='forms.metrics.gewicht.value'
                            :label='$t(forms.metrics.gewicht.label)'
                            :rules='forms.metrics.gewicht.rules'
                            outlined
                            v-input-decimal-converter
                            @keypress="onlyNumbersAllowed"
              >
                <template v-slot:append>
                  <span class='grey4--text'>{{ $t('registration.stepper.metrics.weightUnit') }}</span>
                </template>
              </v-text-field>
              <action-container :stepper='stepper' @goPrev='goPrev(forms.metrics)'/>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step='6'>
            <v-form ref='doctor' @submit.prevent='goNext($refs.doctor, forms.doctor)'>
              <p class='text-h5 mx-auto mb-4'>{{ $t('registration.stepper.doctor.title') }}</p>
              <v-tooltip bottom max-width='400'>
                <template v-slot:activator='{on, attrs}'>
                  <div class='d-flex align-center justify-center primary--text mb-8' v-on='on' v-bind='attrs'>
                    <v-icon color='primary'>info_outline</v-icon>
                    <span style='border-bottom-style: dotted'>{{ $t('registration.stepper.doctor.why.small') }}</span>
                  </div>
                </template>
                <span>{{ $t('registration.stepper.doctor.why.full') }}</span>
              </v-tooltip>

              <template v-if="forms.doctor.huisartsId.value === 'custom'">
                <v-text-field
                    autofocus
                    v-model='forms.doctor.naam.value'
                    :label='$t(forms.doctor.naam.label)'
                    :rules='forms.doctor.naam.rules'
                    outlined/>

                <default-select
                    v-model='forms.doctor.gemeenteId.value'
                    :label='forms.doctor.gemeenteId.label'
                    :rules='forms.doctor.gemeenteId.rules'
                    type='city'/>

                <v-btn class='mb-8' color='primary' @click="forms.doctor.huisartsId.value = ''">
                  {{ $t('registration.stepper.doctor.return-to-choose-doctor') }}
                </v-btn>
              </template>
              <default-select v-else
                              type='doctor'
                              v-model='forms.doctor.huisartsId.value'
                              :label='forms.doctor.huisartsId.label'
                              can-custom
                              clearable
                              autofocus
              />

              <action-container :stepper='stepper' @goPrev='goPrev(forms.doctor)'/>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step='7'>
            <v-form ref='diabeticTeam' @submit.prevent='goNext($refs.diabeticTeam, forms.diabeticTeam)'>
              <p class='text-h5 mx-auto mb-4'>{{ $t('registration.stepper.diabeticTeam.title') }}</p>
              <v-tooltip bottom max-width='400'>
                <template v-slot:activator='{on, attrs}'>
                  <div class='d-flex align-center justify-center primary--text mb-8' v-on='on' v-bind='attrs'>
                    <v-icon color='primary'>info_outline</v-icon>
                    <span style='border-bottom-style: dotted'>{{
                        $t('registration.stepper.diabeticTeam.why.small')
                      }}</span>
                  </div>
                </template>
                <span>{{ $t('registration.stepper.diabeticTeam.why.full') }}</span>
              </v-tooltip>
              <default-select type='diabeticTeam'
                              v-model='forms.diabeticTeam.diabetesteamId.value'
                              :label='forms.diabeticTeam.diabetesteamId.label'
                              clearable
                              autofocus
              />
              <action-container :stepper='stepper' @goPrev='goPrev(forms.diabeticTeam)'/>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step='8'>
            <v-form @submit.prevent.prevent='goNext()'>
              <p class='text-h5 max-auto mb-12'>{{ $t('registration.stepper.language.description') }}</p>
              <div class="d-flex align-center justify-center local-picker-root">
                <v-select :label="$t('common.languages.label')" v-model="forms.language" :items="locales" outlined
                          hide-details/>
              </div>

              <action-container extra-margin :stepper='stepper' @goPrev='goPrev(forms.language)'/>
            </v-form>

          </v-stepper-content>


          <v-stepper-content step='9' class='full-width'>
            <v-form @submit.prevent='goNextTermsAndConditions()'>
              <v-card class='tac'>
                <v-card-title>
                  {{ $t('registration.stepper.tac.title') }}
                </v-card-title>
                <v-card-text v-html="$t('registration.stepper.tac.text')"/>
              </v-card>

              <action-container next-text='registration.stepper.actions.acceptTac'
                                @goPrev='goPrev'
                                big-text
                                :stepper='stepper'/>
            </v-form>

          </v-stepper-content>

          <v-stepper-content step='10' class='thanks' v-if='!hasSubmitError'>
            <v-icon size='200' color='green'>check_circle</v-icon>
            <p class='text-h5 my-4'>{{ $t('registration.stepper.thanks.title', {name: firstName}) }}</p>
            <p class='light--text my-4'>{{ $t('registration.stepper.thanks.description') }}</p>
          </v-stepper-content>

          <v-stepper-content step='10' class='thanks' v-else>
            <v-icon size='200' color='error'>error</v-icon>
            <p class='text-h5 my-4 text-center'>{{
                $t('registration.stepper.thanks_error.title', {name: firstName})
              }}</p>
            <p class='light--text my-4 text-center'>{{ $t('registration.stepper.thanks_error.description') }}</p>
          </v-stepper-content>

        </v-stepper-items>
      </v-stepper>

    </v-stepper>

    <home-back-button-handler :step='stepper' @popstate='goPrev'/>
  </v-card>
</template>

<script>
import LocalePicker from '@/modules/core/components/LocalePicker';
import {RegistrationPersonalForm} from '@/modules/home/forms/registration/personal';
import {RegistrationContactForm} from '@/modules/home/forms/registration/contact';
import {RegistrationDeliveryForm} from '@/modules/home/forms/registration/delivery';
import {RegistrationMetricsForm} from '@/modules/home/forms/registration/metrics';
import {RegistrationDoctorForm} from '@/modules/home/forms/registration/doctor';
import {RegistrationDiabeticTeamForm} from '@/modules/home/forms/registration/diabeticTeam';
import ActionContainer from '@/modules/home/ActionContainer';
import DefaultSelect from '@/modules/core/components/selects/defaultSelect';
import DateField from '@/modules/core/components/forms/DateField';
import {postOnlineRegistration} from '@/modules/home/api';
import PhoneField from '@/modules/core/components/forms/PhoneField';
import HomeBackButtonHandler from '@/modules/home/HomeBackButtonHandler.vue';
import {getLocales} from "@/modules/core/utils";
import {onlyNumberAllowed} from "@/modules/core/directives/inputDecimalConvert";

export default {
  name: 'registration',
  components: {HomeBackButtonHandler, PhoneField, DateField, DefaultSelect, ActionContainer, LocalePicker},
  data() {
    return {
      stepper: 1,
      headerStepper: 1,
      forms: {
        personal: new RegistrationPersonalForm(this.$i18n),
        contact: new RegistrationContactForm(this.$i18n),
        delivery: new RegistrationDeliveryForm(this.$i18n),
        metrics: new RegistrationMetricsForm(this.$i18n),
        doctor: new RegistrationDoctorForm(this.$i18n),
        diabeticTeam: new RegistrationDiabeticTeamForm(),
        language: this.$i18n.locale,
      },
      hasSubmitError: false,
      locales: []
    };
  },
  mounted() {
    this.locales = this.loadLocales()
  },
  computed: {
    firstName() {
      return this.forms.personal.voornaam.value;
    },
    userName() {
      return `${this.forms.personal.voornaam.value} ${this.forms.personal.naam.value}`;
    },
    confirmEmailRule() {
      if (this.forms.contact.email.value !== this.forms.contact.confirmEmail.value) {
        return [this.$t('registration.stepper.contact.confirmEmailNotSame')];
      }

      return [true];
    },
    progressValue() {
      return (this.stepper / 10) * 100;
    },
  },
  methods: {
    onlyNumbersAllowed(evt) {
      return onlyNumberAllowed(evt);
    },
    loadLocales() {
      return getLocales().map(language => ({
        text: this.$t(`common.languages.${language}`),
        value: language
      }));
    },
    goPrev(formData) {
      if (formData && formData.resetFormSubmit) {
        formData.resetFormSubmit();
      }

      this.stepper -= 1;
    },
    goNext(form, formData) {
      if (formData && formData.isSubmitted) {
        formData.isSubmitted();
      }

      this.$nextTick(() => {
        if (!form || form.validate()) {
          this.stepper += 1;
        }
      });
    },
    goNextTermsAndConditions() {
      this.goNext();
      this.handleSubmit();
    },
    async handleSubmit() {
      try {
        await postOnlineRegistration({
          ...this.forms.personal.toJSON(),
          ...this.forms.contact.toJSON(),
          ...this.forms.delivery.toJSON(),
          ...this.forms.metrics.toJSON(),
          ...this.forms.doctor.toJSON(),
          ...this.forms.diabeticTeam.toJSON(),
          language: this.forms.language,
        });
        this.hasSubmitError = false;
      } catch (e) {
        this.hasSubmitError = true;
      }
    },
  },
  watch: {
    '$i18n.locale': function (newVal, oldVal) {
      console.log('locale change', newVal)
      this.forms.language = newVal
    },
    stepper() {
      if (this.stepper === 10) {
        return;
      }

      const stepper2Begin = 4;
      const stepper3Begin = 6;
      const stepper4Begin = 8;

      if (stepper4Begin <= this.stepper) {
        this.headerStepper = 4;
        return;
      }

      if (stepper3Begin <= this.stepper) {
        this.headerStepper = 3;
        return;
      }

      if (stepper2Begin <= this.stepper) {
        this.headerStepper = 2;
        return;
      }

      this.headerStepper = 1;
    },
  },
};
</script>

<style scoped lang='scss'>

::v-deep .v-stepper__wrapper {
  max-width: 400px;
  margin: auto;

  @media screen and (min-width: 600px) {
    //min-height: 400px;
  }
}

.full-width {
  ::v-deep .v-stepper__wrapper {
    max-width: 600px;
  }
}

.tac {
  white-space: pre-wrap;
  text-align: left;
  max-height: 400px;
  overflow: auto;
}

.thanks {
  ::v-deep .v-stepper__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number],
::v-deep input[type=number] {
  -moz-appearance: textfield;
}

.local-picker-root {
  gap: 16px;
  max-width: 400px;
  width: 100%;
  margin: auto;
}

</style>
