import {AxiansForm, FormItem, formRules} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import moment from 'moment';

export class RegistrationPersonalForm extends AxiansForm {
	voornaam: FormItem;
	naam: FormItem;
	geboortedatum: FormItem;
	rijksregisternummer: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.voornaam = new FormItem(
			'firstName',
			'registration.stepper.personal.firstName',
			'',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.naam = new FormItem(
			'lastName',
			'registration.stepper.personal.lastName',
			'',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.geboortedatum = new FormItem(
			'birthday',
			'registration.stepper.personal.birthday',
			'',
			formRules.geboorteDatumValidatie(translator)
		);

		this.rijksregisternummer = new FormItem(
			'IDnumber',
			'registration.stepper.personal.idNumber',
			''
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			firstName: this.voornaam.value,
			lastName: this.naam.value,
			geboortedatum: moment(this.geboortedatum.value).format('DD/MM/YYYY'),
			idNumber: this.rijksregisternummer.value,
		};
	}
}
