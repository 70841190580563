import Vue from 'vue';

// On desktop, we can use the event.code
// On Android, we need to use event.key. For some reason, the event.code is not filled in (Known issue with Android)
const allowedCodes = ['Backspace', 'Delete', 'Minus', 'ArrowLeft', 'ArrowRight', 'Tab'];
const dateDivider = '-';

const handleInputAllowedKeys = (e: KeyboardEvent) => {
	const key = e.key;
	const code = e.code;

	if (Number.isFinite(Number(key)) || allowedCodes.includes(code) || allowedCodes.includes(key)) {
		return;
	}

	e.preventDefault();
};

const handleInputMask = (e: KeyboardEvent) => {
	const target = e.target as HTMLInputElement;
	const key = e.key;
	const code = e.code;

	if (allowedCodes.includes(code) || allowedCodes.includes(key)) {
		return;
	}

	let value = target.value || '';

	value = value.replaceAll(dateDivider, '');

	if (value.length >= 2) {
		value = value.substr(0, 2) + '-' + value.substr(2);
	}
	if (value.length >= 5) {
		value = value.substr(0, 5) + '-' + value.substr(5);
	}

	target.value = value;
};

Vue.directive('input-date-converter', {
	bind: (el: HTMLElement) => {
		const elInput: HTMLInputElement | null = el.querySelector('input');

		if (!elInput) {
			return;
		}

		elInput.setAttribute('type', 'text');
		elInput.setAttribute('pattern', '[0-9]*');
		elInput.setAttribute('inputmode', 'numeric');

		elInput.onkeydown = handleInputAllowedKeys;
		elInput.onkeyup = handleInputMask;
	},
});
