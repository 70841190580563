import { AxiansForm, FormItem, formRules } from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';

export class RegistrationDoctorForm extends AxiansForm {
	huisartsId: FormItem;
	naam: FormItem;
	gemeenteId: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.huisartsId = new FormItem('doctor', 'registration.stepper.doctor.doctor', '');

		this.naam = new FormItem(
			'name',
			'registration.stepper.doctor.name',
			'',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.gemeenteId = new FormItem(
			'city',
			'registration.stepper.doctor.city',
			'',
			formRules.required(translator, 'form.required')
		);
	}

	toJSON(): { [p: string]: any } {
		const isCustomDoctor = this.huisartsId.value === 'custom';
		return {
			doctor: !isCustomDoctor ? this.huisartsId.value : null,
			doctorName: this.naam.value,
			doctorCity: this.gemeenteId.value,
		};
	}
}
