import {Module} from "vuex";
import {router} from "@/modules/core/router/router";
import {ROUTES} from "@/modules/core/router/routes";

export default (): Module<any, any> => ({
    namespaced: true,
    state: {
        sidebarShown: true, // null sets web default to true and mobile to false
        currentItem: undefined,
        items: sidebarItems
    },
    mutations: {
        CURRENT_ITEM(state, item) {
            state.currentItem = item;
        },
        SHOW_SIDEBAR(state) {
            state.sidebarShown = true;
        },
        CLOSE_SIDEBAR(state) {
            state.sidebarShown = false;
        },
        TOGGLE_SIDEBAR(state) {
            state.sidebarShown = !state.sidebarShown;
        },
        SET_SIDEBAR_SHOWN(state, value) {
            state.sidebarShown = value;
        }
    },
    actions: {
        goTo(context, item) {
            context.commit('CURRENT_ITEM', item);
            router.push({name: item.route.name});
        }
    },
    getters: {
        canSeeItem: (state, getters, rootState, rootGetters) => (item) => {
            if (item.route !== undefined) {
                if (item.route.publicRoute) {
                    return true
                } else {
                    let accesRights = rootGetters['authorisationStore/accessRights']
                    return item.route.accessRight == null || accesRights.includes(item.route.accessRight)
                }
            } else {
                return true
            }
        },
    }
});

export const sidebarItems = [
    {icon: 'fa-home', text: 'Home', route: ROUTES.HOME},
];
