<template>
	<v-container fluid>
		<v-row class="container-row">

			<v-col md="4" class="img-container d-none d-md-flex">
				<div class="img-box">
					<v-img src="@/assets/pregnantWoman.png"
								 alt="pregnant woman"
								 contain/>
				</div>
			</v-col>

			<v-col class="form-container">

				<div class="form-box">
					<v-img src="@/assets/logo.svg" alt="Zoet Zwanger" width="200"/>


					<v-card class="d-flex align-center justify-center flex-column pa-4">
						<v-icon size="200" color="error">error</v-icon>
						<p class="text-h5 my-4">{{ $t('404.title') }}</p>
						<p class="light--text my-4 text-center">{{ $t('404.content') }}</p>
					</v-card>

				</div>

			</v-col>

		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'NotFound',
};
</script>

<style lang="scss" scoped>

.container {
	height: 100%;
	max-width: 2000px;
	padding: 0;

	.container-row {
		height: 100%;
		margin: 0;
	}
}

.img-container {
	padding: 0;
	display: flex;
	align-items: flex-end;
}

.img-box {
	max-width: 1000px;
	margin: 0 auto;
}

.form-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-box {
	max-width: 800px;
	width: 100%;
	margin: auto;
}

</style>
