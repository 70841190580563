import VueI18n from 'vue-i18n';
import moment from 'moment/moment';

export abstract class AxiansForm {
	// [formFieldName: string]: FormItem

	public isSubmitted(): void {
		Object.keys(this).forEach((key: string) => (this[key].isSubmitted = true));
	}

	public resetFormSubmit(): void {
		Object.keys(this).forEach((key: string) => (this[key].isSubmitted = false));
	}

	public toJSON(): { [p: string]: any } {
		return {};
	}
}

export const formRules = {
	required: (translator: VueI18n, transKey: string) => [
		(v: string) => !!v || translator.t(transKey),
	],
	isEmail: (translator: VueI18n, transKey: string) => [
		(v: string) => {
			if (!v) {
				// not required
				return true;
			}
			return (
				/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					v
				) || translator.t(transKey)
			);
		},
	],
	isEmailAndRequired: (translator: VueI18n, transKey: string) => [
		(v: string) => {
			return (
				/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
					v
				) || translator.t(transKey)
			);
		},
	],
	maxChars: (translator: VueI18n, max: number) => [
		(v: string) => {
			if (!v) {
				return translator.t('form.required');
			}

			if (v.length > max) {
				return translator.t('form.maxChars', { max });
			}

			return true;
		},
	],
	numberBetween: (translator: VueI18n, min: number, max: number) => [
		(v: string) => {
			if (!v) {
				return true;
			}

			const valueInNumber = Number(v);

			if (!isFinite(Number(v))) {
				return translator.t('form.number');
			}

			if (min > valueInNumber) {
				return translator.t('form.numberMin');
			}

			if (max < valueInNumber) {
				return translator.t('form.numberMax');
			}

			return true;
		},
	],
	geboorteDatumValidatie: (translator: VueI18n) => [
		(v: string) => {
			if (!v) {
				return translator.t('form.required');
			}

			const geboorteDatum = moment(v, 'DD-MM-YYYY', true);

			if (!geboorteDatum.isValid()) {
				return translator.t('form.date');
			}

			if (
				!geboorteDatum.isBetween(moment().subtract(100, 'years'), moment().subtract(10, 'years'))
			) {
				return translator.t('form.geboorteDatumValid');
			}

			return true;
		},
	],
};

export class FormItem {
	private _id: string;
	private _label: string;
	private _value: any;
	private _rules?: Array<Function>;
	private _counter?: number;
	private _selectItems?: Array<SelectItem>;
	private _isSubmitted: boolean;

	constructor(
		id: string,
		label: string,
		value: any = '',
		rules?: Array<Function>,
		counter?: number,
		selectItems?: Array<SelectItem>
	) {
		this._id = id;
		this._label = label;
		this._value = value;
		this._rules = rules;
		this._counter = counter;
		this._selectItems = selectItems;
		this._isSubmitted = false;
	}

	get id(): string {
		return this._id;
	}

	get label(): string {
		return this._label;
	}

	get rules(): Array<Function> | undefined {
		return this._isSubmitted ? this._rules : undefined;
	}

	get value(): any {
		return this._value;
	}

	set value(value: any) {
		this._value = value;
		this._isSubmitted = false;
	}

	get selectItems(): Array<SelectItem> {
		return this._selectItems || [];
	}

	set isSubmitted(value: boolean) {
		this._isSubmitted = value;
	}
}

export class SelectItem {
	private text: string;
	private _value: string;

	constructor(text: string, value: string) {
		this.text = text;
		this._value = value;
	}

	get value(): string {
		return this._value;
	}
}
