<template>
	<v-container fluid>
		<v-row class='container-row'>

			<v-col md='4' class='img-container d-none d-md-flex'>
          <img class="img-box" src="@/assets/pregnantWoman.png" alt="pregnant woman" />
			</v-col>

			<v-col class='form-container'>

				<div class='form-box'>
					<v-img class="mb-2" src='@/assets/logo.svg' alt='Zoet Zwanger' width='250' />

					<registration v-if="showFlow === 'registration'" />
					<m3 v-if="showFlow === '3m'" :vrouw='vrouw' :opvolging='opvolging' :hash='hash' />
					<xm v-if="showFlow === 'xm'" :vrouw='vrouw' :opvolging='opvolging' :hash='hash' />
					<quiter v-if="showFlow === 'quit'" :vrouw='vrouw' :hash='hash' />

					<v-card v-if="showFlow === 'error'" class='d-flex align-center justify-center flex-column pa-4'>
						<v-icon size='200' color='error'>error</v-icon>
						<p class='text-h5 my-4 text-center'>{{ $t('error.title') }}</p>
						<p class='light--text my-4 text-center'>{{ $t('error.content') }}</p>
					</v-card>

					<v-card v-if="showFlow === 'quitError'" class='d-flex align-center justify-center flex-column pa-4'>
						<v-icon size='200' color='error'>error</v-icon>
						<p class='text-h5 my-4'>{{ $t('uitschrijven.404.title') }}</p>
						<p class='light--text my-4 text-center'>{{ $t('uitschrijven.404.content') }}</p>
					</v-card>

				</div>

			</v-col>

		</v-row>
	</v-container>
</template>

<script>
import Vue from 'vue';
import Registration from '@/modules/home/registration.vue';
import m3 from '@/modules/home/3m.vue';
import xm from '@/modules/home/xm.vue';
import {getOnlineOpvolging, getVrouwDoorId} from '@/modules/home/api';
import Quiter from '@/modules/home/Quiter';

export default Vue.extend({
	name: 'home',
	components: { Quiter, xm, m3, Registration },
	data() {
		return {
			hash: '',
			vrouw: null,
			opvolging: null,
			showFlow: 'not_set_yet',
		};
	},
	mounted() {
		const query = this.$route.query;

		this.hash = query.hash || "";

		if (query.opvolgingId) {
			this.fetchOpvolging(query.opvolgingId.toString(), this.hash);
			return;
		}

		if (query.stopzettingId) {
			this.fetchStopzetting(query.stopzettingId.toString(), this.hash);
			return;
		}

		this.showFlow = 'registration';
	},
	methods: {
		async fetchOpvolging(opvolgingId, hash) {
			try {
				const response = await getOnlineOpvolging(opvolgingId, hash);
				const type = response.opvolgingTypeSwitch;
				this.vrouw = response.vrouw;
        this.$vuetify.lang.current = this.vrouw.taal;
        this.$i18n.locale = this.vrouw.taal;
        this.opvolging = response.opvolging;
				this.showFlow = type || 'error';
			} catch (e) {
				this.showFlow = 'error';
			}
		},
		async fetchStopzetting(stopzettingId, hash) {
			try {
				this.vrouw = await getVrouwDoorId(stopzettingId, hash);
        this.$vuetify.lang.current = this.vrouw.taal;
        this.$i18n.locale = this.vrouw.taal;
				this.showFlow = 'quit';
			} catch (e) {
				if (Array.isArray(e) && e.find(err => err.code === 404)) {
					this.showFlow = 'quitError';
					return;
				}
				this.showFlow = 'error';
			}
		},
	},
});
</script>

<style lang='scss' scoped>

.container {
	height: 100%;
	max-width: 2000px;
	padding: 0;

	.container-row {
		height: 100%;
		margin: 0;
	}
}

.img-container {
	padding: 0;
	display: flex;
	align-items: flex-end;
}

.img-box {
  height: 75vh;
	margin: 0 auto;
  object-fit: contain;
  object-position: center;
}

.form-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.form-box {
	max-width: 800px;
	width: 100%;
	margin: auto;
}

::v-deep .v-stepper__content {
	text-align: center;

	.v-stepper__wrapper {
		overflow: unset;
	}
}
</style>
