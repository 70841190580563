import { AxiansForm, FormItem, formRules } from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import moment from 'moment';

const vermoedelijkeBevallingsDatumValidatie = (translator: VueI18n) => [
	(v: string) => {
		if (!v) {
			return translator.t('form.required');
		}

		const vermoedelijkeBevallingsDatum = moment(v, 'DD-MM-YYYY', true);

		if (!vermoedelijkeBevallingsDatum.isValid()) {
			return translator.t('form.date');
		}

		if (
			!vermoedelijkeBevallingsDatum.isBetween(
				moment().subtract(15, 'years'),
				moment().add(9, 'months')
			)
		) {
			return translator.t('form.vermoedelijkeBevallingsDatumValidatie');
		}

		return true;
	},
];

export class RegistrationDeliveryForm extends AxiansForm {
	vermoedelijkeBevallingsDatum: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.vermoedelijkeBevallingsDatum = new FormItem(
			'deliveryDate',
			'registration.stepper.delivery.deliveryDate',
			'',
			vermoedelijkeBevallingsDatumValidatie(translator)
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			deliveryDate: moment(this.vermoedelijkeBevallingsDatum.value).format('DD/MM/YYYY'),
		};
	}
}
