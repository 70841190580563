import {AxiansForm, FormItem, formRules} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';

export class RegistrationContactForm extends AxiansForm {
	email: FormItem;
	confirmEmail: FormItem;
	adres: FormItem;
	gemeenteId: FormItem;
	telefoon: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.email = new FormItem(
			'email',
			'registration.stepper.contact.email',
			'',
			formRules.isEmailAndRequired(translator, 'form.email')
		);

		this.confirmEmail = new FormItem(
			'confirmEmail',
			'registration.stepper.contact.confirmEmail',
			''
		);

		this.adres = new FormItem(
			'streetAndHouseNumber',
			'registration.stepper.contact.streetAndHouseNumber',
			'',
			formRules.required(translator, 'form.required').concat(formRules.maxChars(translator, 100))
		);

		this.gemeenteId = new FormItem(
			'city',
			'registration.stepper.contact.city',
			'',
			formRules.required(translator, 'form.required')
		);

		this.telefoon = new FormItem('phone', 'registration.stepper.contact.phone', '');
	}

	toJSON(): { [p: string]: any } {
		return {
			email: Boolean(this.email.value) ? this.email.value : null,
			streetNameAndHouseNumber: this.adres.value,
			city: this.gemeenteId.value,
			telefoon: this.telefoon.value,
		};
	}
}
