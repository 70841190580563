<template>
  <v-card>
    <v-card-title class="quitter__header">
      <p class="mb-0 flow-title"> {{ $t('uitschrijven.title') }} </p>
      <v-spacer/>
      <p class='mb-0'>{{ this.vrouw.fullName }}</p>
    </v-card-title>
    <v-card-text class="white-space-pre-line pb-0" v-if="step === 1">
      <h2 class="quitter__title">{{ $t('uitschrijven.greetings', {name: this.vrouw.fullName}) }}</h2>
      <p class="quitter__text">
        {{ $t('uitschrijven.content') }}
      </p>
    </v-card-text>

    <v-card-text v-if="step === 2" class="d-flex align-center justify-center flex-column">
      <v-icon size="200" color="green">check_circle</v-icon>
      <p class="my-4 white-space-pre-line">{{ $t('uitschrijven.content_submit') }}</p>
    </v-card-text>

    <v-card-text v-if="step === 3" class="d-flex align-center justify-center flex-column">
      <v-icon size="200" color="green">check_circle</v-icon>
      <p class="my-4 white-space-pre-line">{{ $t('uitschrijven.content_cancel') }}</p>
    </v-card-text>

    <v-card-actions class="quitter__actions" v-if="step === 1">
      <v-btn color="primary" outlined large @click="handleCancel" class="quitter__btn">
        {{ $t('uitschrijven.actions.cancel') }}
      </v-btn>
      <v-btn color="primary" large @click="handleSubmit" class="quitter__btn">
        {{ $t('uitschrijven.actions.submit') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {schrijfVrouwUit} from "@/modules/home/api";
import {Vrouw} from "@/modules/vrouw/domains";

export default {
  name: "Quiter",
  props: {
    vrouw: Vrouw,
    hash: String
  },
  data() {
    return {
      step: 1
    }
  },
  methods: {
    handleCancel: function () {
      this.step = 3;
    },
    handleSubmit: async function () {
      await schrijfVrouwUit(this.vrouw.vrouwId, this.hash)
      this.step = 2;
    }
  }
}
</script>

<style lang="scss" scoped>
.quitter {
  &__header {
    border-bottom: 1px solid rgba(0,0,0,.15);
    margin-bottom: 3rem;
  }

  &__title {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: #000000;
    font-weight: 500;
  }

  &__text {
    font-size: 1.25rem;
  }

  &__actions {
    display: flex;
    align-items: center;
    padding: 2rem 2rem;
  }

  &__btn {
    flex: 1 1 50%;
  }
}

.white-space-pre-line {
  white-space: pre-line;
}

.v-card__text {
  padding-left: 2rem;
}
</style>
