<template>
  <div>
    <v-stepper-content :step='beginStepper'>
      <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
        {{ $t('userInfo.title') }}</p>
      <v-form ref='userInfo' @submit.prevent='goNext("userInfo")'>
        <v-text-field v-model='form.voornaam.value'
                      autofocus
                      :label='$t(form.voornaam.label)'
                      :rules='form.voornaam.rules'
                      outlined
        />
        <v-text-field v-model='form.naam.value'
                      :label='$t(form.naam.label)'
                      :rules='form.naam.rules'
                      outlined
        />
        <date-field v-model='form.geboortedatum.value'
                    :label='form.geboortedatum.label'
                    :rules='form.geboortedatum.rules'
        />
        <action-container @goPrev='goPrev("userInfo")' :stepper='stepper'/>
      </v-form>
    </v-stepper-content>
    <v-stepper-content :step='beginStepper + 1'>
      <p class='text-h5 mx-auto mb-12' style='white-space: pre-wrap'>
        {{ $t('userInfo.title1') }}</p>
      <v-form ref='userInfo1' @submit.prevent='goNextBeforeCommunication("userInfo1")'>
        <v-text-field v-model='form.email.value'
                      autofocus
                      :label='$t(form.email.label)'
                      :rules='form.email.rules'
                      outlined
                      type='email'
        />
        <v-text-field v-model='form.confirmEmail.value'
                      :label='$t(form.confirmEmail.label)'
                      :rules='confirmEmailRule'
                      outlined
                      type='email'
        />
        <v-text-field v-model='form.adres.value'
                      :label='$t(form.adres.label)'
                      :rules='form.adres.rules'
                      outlined
        />
        <default-select type='city' v-model='form.gemeenteId.value'
                        :label='form.gemeenteId.label'
                        :rules='form.gemeenteId.rules'/>
        <phone-field v-model='form.telefoon.value'
                     :label='$t(form.telefoon.label)'/>

        <action-container @goPrev='goPrev("userInfo1")' :stepper='stepper'/>
      </v-form>
    </v-stepper-content>
    <v-stepper-content :step='beginStepper + 2'>
      <p class='text-h5 mx-auto mb-4' style='white-space: pre-wrap'>
        {{ $t('userInfo.title2') }}</p>

      <v-tooltip bottom max-width='400'>
        <template v-slot:activator='{on, attrs}'>
          <div class='d-flex align-center justify-center primary--text mb-8' v-on='on' v-bind='attrs'>
            <v-icon color='primary'>info_outline</v-icon>
            <span style='border-bottom-style: dotted'>{{ $t('userInfo.communication.why.small') }}</span>
          </div>
        </template>
        <span>{{ $t('userInfo.communication.why.full') }}</span>
      </v-tooltip>

      <v-form ref='userInfo2' @submit.prevent='goNextCommunication()'>
        <v-card v-for='item in form.communicatie.selectItems'
                :key='item.value'
                class="card-option"
                :class="{'card-option--selected': isActive(item.value, form.communicatie.value)}"
                @click='handleCommunicationClick(item)'>
          <span>{{ $t(item.text) }}</span>
        </v-card>
        <p class='error--text' v-if='communicationError'>{{ $t('userInfo.communication.required') }}</p>
        <action-container @goPrev='goPrev()' no-going-next big-text :stepper='stepper'/>
      </v-form>
    </v-stepper-content>
    <v-stepper-content :step='beginStepper + 3'>
      <p class='text-h5 mx-auto mb-4' style='white-space: pre-wrap'>
        {{ $t('userInfo.title3') }}</p>

      <v-tooltip bottom max-width='400'>
        <template v-slot:activator='{on, attrs}'>
          <div class='d-flex align-center justify-center primary--text mb-8' v-on='on' v-bind='attrs'>
            <v-icon color='primary'>info_outline</v-icon>
            <span style='border-bottom-style: dotted'>{{ $t('userInfo.doubleConfirmEmail.why.small') }}</span>
          </div>
        </template>
        <span>{{ $t('userInfo.doubleConfirmEmail.why.full') }}</span>
      </v-tooltip>

      <v-form ref='userInfo3' @submit.prevent='goNext("userInfo3")'>
        <v-row>
          <v-col>
            <v-text-field v-model='form.doubleEmail.value'
                          :label='$t(form.doubleEmail.label)'
                          :rules='form.doubleEmail.rules'
                          outlined
                          type='email'
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field v-model='form.doubleConfirmEmail.value'
                          :label='$t(form.doubleConfirmEmail.label)'
                          :rules='doubleConfirmEmailRule'
                          outlined
                          type='email'
            />
          </v-col>
        </v-row>
        <action-container @goPrev='goPrev("userInfo3")' :stepper='stepper'/>
      </v-form>

    </v-stepper-content>
  </div>
</template>

<script>
import ActionContainer from '@/modules/home/ActionContainer';
import {UserInfoCommunication, UserInfoSteppersForm} from '@/modules/home/forms/userInfoSteppersForm';
import DefaultSelect from '@/modules/core/components/selects/defaultSelect';
import DateField from '@/modules/core/components/forms/DateField';
import {Vrouw} from '@/modules/vrouw/domains';
import PhoneField from '@/modules/core/components/forms/PhoneField';

//TODO: replace all references uf userInfo, userInfo1, userInfo3, etc with the UserInfoStep enum
//TODO: make distinction between goNext and goNextBeforeCommunication based on the passed UserInfoStep enum
export default {
  name: 'userInfoSteppers',
  components: {PhoneField, DateField, DefaultSelect, ActionContainer},
  props: {
    stepper: Number,
    beginStepper: Number,
    vrouw: Vrouw,
    skipCommunication: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: new UserInfoSteppersForm(this.$i18n, this.vrouw),
      communicationError: false,
      steps: {},
    };
  },
  mounted() {
    this.steps = {
      userInfo: [this.form.voornaam, this.form.naam, this.form.geboortedatum],
      userInfo1: [this.form.email, this.form.confirmEmail, this.form.adres, this.form.gemeenteId, this.form.telefoon],
      userInfo3: [this.form.doubleEmail, this.form.doubleConfirmEmail],
    };
  },
  methods: {
    goNext(form) {
      if (form) {
        this.steps[form].forEach(formItem => formItem.isSubmitted = true);
      }
      this.$nextTick(() => {
        this.$emit('goNext', this.$refs[form], this.form);
      });
    },
    goNextBeforeCommunication(form) {
      if (form) {
        this.steps[form].forEach(formItem => formItem.isSubmitted = true);
      }
      this.$nextTick(() => {
        this.$emit('goNextBeforeCommunication', this.$refs[form], this.form);
      });
    },
    goPrev(form) {
      if (form) {
        this.steps[form].forEach(formItem => formItem.isSubmitted = false);
      }
      this.$emit('goPrev');
    },
    isActive(itemValue, formValue) {
      return itemValue === formValue;
    },
    getIcon(isActive) {
      return isActive ? 'done' : 'close';
    },
    getClass(isActive) {
      let output = 'my-8 py-5 px-2';

      if (isActive) {
        output = `${output} control-active`;
      }

      return output;
    },
    handleCommunicationClick(item) {
      this.form.communicatie.value = item.value;
      this.communicationError = false;
      this.goNextCommunication();
    },
    goNextCommunication() {
      if (!this.form.communicatie.value) {
        this.communicationError = true;
        return;
      }

      if (this.form.communicatie.value === UserInfoCommunication.email && !this.form.email.value) {
        // go to email confirm
        this.goNext();
      } else {
        // go to the thanks and skip email confirm
        this.goNext();
        this.goNext();
      }

    },
  },
  computed: {
    confirmEmailRule() {
      if (this.form.email.value !== this.form.confirmEmail.value) {
        return [this.$t('form.confirmEmailNotSame')];
      }

      return [true];

    },
    doubleConfirmEmailRule() {
      if (this.form.doubleEmail.value !== this.form.doubleConfirmEmail.value) {
        return [this.$t('form.confirmEmailNotSame')];
      }

      if (!this.form.doubleConfirmEmail.value) {
        return [this.$t('form.email')];
      }

      return [true];

    },
  },
  watch: {
    'form.email.value': function () {
      this.form.doubleEmail.value = this.form.email.value;
    },
  },
};
</script>

<style scoped lang='scss'>
.control-active {
  border: solid 2px var(--v-primary-base);
  background-color: var(--v-primary-base);
  color: white;
}
</style>
