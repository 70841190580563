import {
	AxiansForm,
	FormItem,
	formRules,
	SelectItem,
} from '@/modules/core/components/forms/forms.model';
import VueI18n from 'vue-i18n';
import moment from 'moment';

export enum ControlOptions {
	YES = 'J',
	NO = 'N',
	NO_BREASTFEEDING = 'Borstvoeding',
	NO_PLANNED = 'Gepland',
	NIET_GEKEND = '',
}

export enum DiabetisOptions {
	YES = 'true',
	NO ='false'
}

const uiteindelijkeBevallingsDatumValidatie = (translator: VueI18n) => [
	(v: string) => {
		if (!v) {
			return translator.t('form.required');
		}

		const datum = moment(v, 'DD-MM-YYYY', true);

		if (!datum.isValid()) {
			return translator.t('form.date');
		}

		if (!datum.isBetween(
			moment().subtract(15, 'years'),
			moment().add(9, 'months')
		)) {
			return translator.t('form.uiteindelijkeBevallingsDatum');
		}

		return true;
	},
];

export class M3Form extends AxiansForm {
	uiteindelijkeBevallingsDatum: FormItem;
	bloedglucosecontrole: FormItem;
	diabetis: FormItem;

	constructor(translator: VueI18n) {
		super();

		this.uiteindelijkeBevallingsDatum = new FormItem(
			'deliveryDate',
			'3m.stepper.form.deliveryDate.label',
			'',
			uiteindelijkeBevallingsDatumValidatie(translator)
		);

		this.bloedglucosecontrole = new FormItem(
			'control',
			'',
			'',
			formRules.required(translator, 'form.required'),
			-1,
			[
				new SelectItem('3m.stepper.form.control.options.yes', ControlOptions.YES),
				new SelectItem('3m.stepper.form.control.options.planned', ControlOptions.NO_PLANNED),
				new SelectItem(
					'3m.stepper.form.control.options.breastfeeding',
					ControlOptions.NO_BREASTFEEDING
				),
				new SelectItem('3m.stepper.form.control.options.no', ControlOptions.NO),
			]
		);

		this.diabetis = new FormItem(
			'diabetic',
			'',
			'',
			formRules.required(translator, 'form.required'),
			-1,
			[
				new SelectItem('3m.stepper.form.diabetic.options.yes', DiabetisOptions.YES),
				new SelectItem('3m.stepper.form.diabetic.options.no', DiabetisOptions.NO),
			]
		);
	}

	toJSON(): { [p: string]: any } {
		return {
			naBevallingDiabetes: this.diabetis.value === DiabetisOptions.YES,
			uiteindelijkeBevallingsDatum: moment(this.uiteindelijkeBevallingsDatum.value).format(
				'YYYY-MM-DD'
			),
			naBevallingBloedafname: this.bloedglucosecontrole.value,
		};
	}
}
