import moment from "moment/moment";
import {getValueFromObject} from "@/modules/core/utils";

export class Vrouw {
    vrouwId: string;
    voornaam: string;
    naam: string;
    geboortedatum: moment.Moment | null;
    email: string;
    adres: string;
    gemeenteId: string;
    telefoon: string;
    taal: string;
    voorkeurEmail: boolean;
    comment: string;
    huisartsId: string;
    extraDokterData: { naam: string, gemeenteId: string };
    diabetesteamId: string;
    gewicht: string;
    length: string;
    stopzetting: any | null;


    constructor(woman: any) {
        const contact = woman.contact;

        this.vrouwId = woman.vrouwId;
        this.voornaam = woman.voornaam;
        this.naam = woman.naam;
        this.geboortedatum = woman.geboortedatum ? moment(woman.geboortedatum) : null;
        this.email = contact?.email || "";
        this.adres = getValueFromObject(woman, 'contact.adres', '')
        this.gemeenteId = getValueFromObject(woman, 'contact.gemeenteId', '')
        this.telefoon = getValueFromObject(woman, 'contact.telefoon', "")
        this.taal = getValueFromObject(woman, 'taal', "")
        this.voorkeurEmail = woman?.contact?.voorkeurEmail;
        this.comment = getValueFromObject(woman, 'comment', "")
        this.huisartsId = getValueFromObject(woman, 'huisartsId', "")
        this.extraDokterData = {
            naam: getValueFromObject(woman, 'onlineHuisartsData.naam', ""),
            gemeenteId: getValueFromObject(woman, 'onlineHuisartsData.gemeenteId', ""),
        }
        this.diabetesteamId = getValueFromObject(woman, 'diabetesteamId', "")
        this.gewicht = getValueFromObject(woman, 'prenataal.gewicht', '')
        this.length = getValueFromObject(woman, 'prenataal.length', '');
        this.stopzetting = getValueFromObject(woman, 'stopzetting', null)
    }

    get fullName(): string {
        if (!this.voornaam || !this.naam) {
            return this.email
        }

        return `${this.voornaam} ${this.naam}`
    }
}